import React from 'react'
import moment from 'moment'
import clsx from 'clsx'
import {
  withStyles,
  WithStyles,
  Typography,
  Grid,
  Paper,
  Theme,
  createStyles,
} from '@material-ui/core'
import { GraphsState } from 'redux/modules/graphs'
import StressCircle from './StressCircle'
import PercentageLabel from './PercentageLabel'
import Graph from './Graph'
import { TrendType } from 'utils/trendType'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
    },
    title: {
      fontSize: 17,
      fontWeight: 'bold',
    },
    circle: {
      width: 200,
      height: 200,
      margin: 10,
    },
    conditionContainer: {
      width: 200,
      height: 250,
    },
    graph: {
      width: 'calc(100% - 10px)',
      height: 250,
      margin: 5,
    },
  })

type StressGraphProps = WithStyles<typeof styles> & {
  className?: string
  graphs: GraphsState
}

const StressGraph = (props: StressGraphProps) => {
  const { classes, className, graphs } = props
  if (!graphs.data) return <></>
  const { type, from, to } = graphs.trend
  const { stresses } = graphs.data

  const values: {
    label: string | null
    value: number | null
  }[] = []

  const date = moment(from)
  while (date < to) {
    const stress = stresses.find((stress) => stress.date.unix() === date.unix())
    values.push({
      label: TrendType.graphLabel(type, date),
      value: stress ? stress.averageValue : null,
    })
    type === TrendType.Day ? date.add(10, 'minute') : date.add(1, TrendType.graphUnit(type))
  }

  let normalCount = 0
  let warningCount = 0
  let dangerCount = 0
  stresses.forEach((stress) => {
    normalCount += stress.normalCount
    warningCount += stress.warningCount
    dangerCount += stress.dangerCount
  })
  const totalCount = normalCount + warningCount + dangerCount
  const normalRatio = normalCount / totalCount || 0
  const warningRatio = warningCount / totalCount || 0
  const dangerRatio = dangerCount / totalCount || 0

  return (
    <Paper className={clsx(classes.container, className)}>
      <Typography className={classes.title}>ストレス</Typography>
      <Grid container direction="row" alignItems="center" spacing={2}>
        <Grid item>
          <StressCircle
            className={classes.circle}
            minutes={totalCount * 10}
            normal={normalRatio}
            warning={warningRatio}
            danger={dangerRatio}
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-between"
          className={classes.conditionContainer}>
          <Grid item>
            <PercentageLabel condition={0} minutes={normalCount * 10} percentage={normalRatio} />
          </Grid>
          <Grid item>
            <PercentageLabel condition={1} minutes={warningCount * 10} percentage={warningRatio} />
          </Grid>
          <Grid item>
            <PercentageLabel condition={2} minutes={dangerCount * 10} percentage={dangerRatio} />
          </Grid>
        </Grid>
        <Grid item xs>
          <Graph className={classes.graph} values={values} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(StressGraph)
