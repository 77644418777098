import {
  Store as rStore,
  createStore as _createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import sagas from 'redux/sagas'
import reducers from 'redux/modules'
import { AlertsState } from 'redux/modules/alerts'
import { AuthState } from 'redux/modules/auth'
import { GraphsState } from 'redux/modules/graphs'
import { NotificationsState } from 'redux/modules/notifications'
import { ObservationsState } from 'redux/modules/observations'
import { UserGroupsState } from './modules/userGroups'
import { UsersState } from './modules/users'

export type AppState = {
  alerts: AlertsState
  auth: AuthState
  graphs: GraphsState
  notifications: NotificationsState
  observations: ObservationsState
  userGroups: UserGroupsState
  users: UsersState
}

export type Store = rStore<AppState>

const saga = createSagaMiddleware()
const middlewares = [saga]
const createStore = applyMiddleware(...middlewares)(_createStore)
const store = createStore(combineReducers<AppState>(reducers))

saga.run(sagas)

export default store
