import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { EditPasswordStoreStates, EditPasswordActions } from '.'
import validator from 'utils/validator'

import { Grid, Typography, Paper, WithStyles, withStyles } from '@material-ui/core'
import { MainContainer, SectionHeader, FormContainer, Form, GreenButton } from 'components'
import styles from './styles'

type EditPasswordProps = WithStyles<typeof styles> &
  RouteComponentProps &
  EditPasswordStoreStates &
  EditPasswordActions

interface EditPasswordStates {
  currentPassword: string
  newPassword: string
  confirmationPassword: string
  // validation
  isEmpty: boolean
  isMatchPassword: boolean
}

type EditPasswordItem = Pick<
  EditPasswordStates,
  'currentPassword' | 'newPassword' | 'confirmationPassword'
>

class EditPassword extends React.Component<EditPasswordProps, EditPasswordStates> {
  constructor(props: EditPasswordProps) {
    super(props)

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmationPassword: '',
      isEmpty: true,
      isMatchPassword: true,
    }
  }

  componentDidUpdate(prevProps: EditPasswordProps) {
    const { auth } = this.props
    const { changingPassword, changePasswordError } = auth

    if (!prevProps.auth.changingPassword || changingPassword || changePasswordError) return

    // パスワード変更完了
    setTimeout(() => {
      alert('パスワードの変更が完了しました')

      this.setState({
        currentPassword: '',
        newPassword: '',
        confirmationPassword: '',
        isEmpty: true,
        isMatchPassword: true,
      })
    }, 300)
  }

  onChangeValue(key: keyof EditPasswordItem, value: string) {
    const state = { ...this.state }
    state[key] = value
    state.isEmpty = Object.values(state).includes('')
    state.isMatchPassword = state.newPassword === state.confirmationPassword
    this.setState(state)
  }

  onSubmit() {
    const { newPassword, currentPassword, isEmpty, isMatchPassword } = this.state

    if (isEmpty || !isMatchPassword) return

    try {
      validator.notEmpty(newPassword, currentPassword)
      validator.password(newPassword)
    } catch (err) {
      alert(err)
      return
    }

    this.props.changePassword({ currentPassword, newPassword })
  }

  render() {
    const {
      currentPassword,
      newPassword,
      confirmationPassword,
      isEmpty,
      isMatchPassword,
    } = this.state
    const { classes, auth } = this.props

    return (
      <MainContainer>
        <SectionHeader>パスワード変更</SectionHeader>

        <FormContainer>
          <Form
            value={currentPassword}
            label="現在のパスワード"
            type="password"
            onChange={(value) => this.onChangeValue('currentPassword', value)}
          />
          <Form
            value={newPassword}
            label="新しいパスワード"
            type="password"
            onChange={(value) => this.onChangeValue('newPassword', value)}
          />
          <Form
            value={confirmationPassword}
            label="新しいパスワード"
            type="password"
            onChange={(value) => this.onChangeValue('confirmationPassword', value)}
          />
        </FormContainer>

        <Paper square elevation={0} className={classes.actionContainer}>
          <Grid container item xs direction="column" alignItems="flex-end" spacing={1}>
            {auth.changePasswordError && (
              <Grid>
                <Typography color="secondary">{auth.changePasswordError}</Typography>
              </Grid>
            )}
            <Grid item>
              <GreenButton
                disabled={isEmpty || !isMatchPassword || auth.changingPassword}
                variant="contained"
                title={auth.changingPassword ? '登録中' : '登録する'}
                onClick={() => this.onSubmit()}
              />
            </Grid>
          </Grid>
        </Paper>
      </MainContainer>
    )
  }
}

export default withRouter(withStyles(styles)(EditPassword))
