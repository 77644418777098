import moment from 'moment'
import { formatDateTime } from 'utils/moment'
import { AlertActionData } from 'utils/apiClient'
import User from './user'

export const AlertActionType = {
  EMAIL: 1,
  PHONE: 2,
} as const
export type AlertActionType = typeof AlertActionType[keyof typeof AlertActionType]

export const alertActionTypeText = (type: AlertActionType) => {
  switch (type) {
    case AlertActionType.EMAIL:
      return 'メール'
    case AlertActionType.PHONE:
      return '電話'
    default:
      return 'その他'
  }
}

export default class AlertAction {
  id: number
  type: AlertActionType
  message: string
  user: User
  actedUser: User
  createdAt: moment.Moment

  get typeText(): string {
    return alertActionTypeText(this.type)
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  constructor(
    id: number,
    type: AlertActionType,
    message: string,
    user: User,
    actedUser: User,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.type = type
    this.message = message
    this.user = user
    this.actedUser = actedUser
    this.createdAt = createdAt
  }

  static load(data: AlertActionData): AlertAction {
    return new AlertAction(
      data.id,
      data.type,
      data.message,
      User.load(data.user),
      User.load(data.actedUser),
      moment(data.createdAt),
    )
  }

  static loadAll(data: AlertActionData[]): AlertAction[] {
    return data.map((item) => this.load(item))
  }
}
