import React, { PropsWithChildren } from 'react'
import moment from 'moment'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Grid,
  TextField,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import DateMomentUtils from '@date-io/moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import { Search } from '@material-ui/icons'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    formContainer: {
      margin: theme.spacing(1),
    },
    label: {
      minWidth: 100,
    },
    form: {
      minWidth: 200,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },

    periodSeparator: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    picker: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    searchForm: {
      backgroundColor: '#ffffff',
      minWidth: 520,
    }
  })

// Form --------------

type FormProps = WithStyles<typeof styles> & {
  label: string
  value: string
  caption?: string
  type?: React.InputHTMLAttributes<unknown>['type']
  onChange: (text: string) => void
}

const _Form = (props: FormProps) => {
  const { classes, label, value, caption, type, onChange } = props

  return (
    <Grid container direction="row" alignItems="center" className={classes.formContainer}>
      <Grid item>
        <Typography align="right" className={classes.label}>
          {label}：
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          className={classes.form}
          value={value}
          type={type}
          onChange={(e) => onChange(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Typography variant="caption">{caption}</Typography>
      </Grid>
    </Grid>
  )
}

export const Form = withStyles(styles)(_Form)

// SearchForm --------------

type SearchFormProps = WithStyles<typeof styles> & {
  label: string
  value: string
  caption?: string
  type?: React.InputHTMLAttributes<unknown>['type']
  onChange: (text: string) => void
  onClickIcon: (value: string) => void
}
const _SearchForm = (props: SearchFormProps) => {
  const { classes, label, value, caption, type, onChange, onClickIcon } = props

  return (
    <Grid container direction="row" alignItems="center" className={classes.formContainer}>
      <Grid item>
        <Typography align="right" className={classes.label}>
          {label}：
        </Typography>
      </Grid>
      <Grid item>
        <TextField 
          className={classes.searchForm}
          value={value}
          type={type}
          onChange={(e) => onChange(e.target.value)}
          variant={"outlined"}
          InputProps={{
            style: {
              borderRadius: 0,
              paddingRight: 5,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size='small'
                  onClick={() => onClickIcon(value)}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            )
          }}
          inputProps={{
            style: {
              paddingTop: 7,
              paddingBottom: 7,
              paddingLeft: 5,
            },
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="caption">{caption}</Typography>
      </Grid>
    </Grid>
  )
}

export const SearchForm = withStyles(styles)(_SearchForm)

// SelectForm --------------

type SelectFormProps = WithStyles<typeof styles> & {
  label: string
  value: string
  options: { label: string; value: string | number }[]
  onChange: (value: string) => void
}

const _SelectForm = (props: SelectFormProps) => {
  const { classes, label, value, options, onChange } = props

  return (
    <Grid container direction="row" alignItems="center" className={classes.formContainer}>
      <Grid item>
        <Typography align="right" className={classes.label}>
          {label}：
        </Typography>
      </Grid>
      <Grid item>
        <FormControl className={classes.form}>
          <Select value={value} onChange={(e) => onChange(e.target.value as string)}>
            {options.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export const SelectForm = withStyles(styles)(_SelectForm)

// MultipleSelectForm --------------

type MultipleSelectFormProps = WithStyles<typeof styles> & {
  label: string
  values: string[]
  options: { label: string; value: string | number }[]
  onChange: (values: string[]) => void
}

const _MultipleSelectForm = (props: MultipleSelectFormProps) => {
  const { classes, label, values, options, onChange } = props

  return (
    <Grid container direction="row" alignItems="center" className={classes.formContainer}>
      <Grid item>
        <Typography align="right" className={classes.label}>
          {label}：
        </Typography>
      </Grid>
      <Grid item>
        <FormControl className={classes.form}>
          <Select
            multiple
            value={values}
            onChange={(e) => onChange(e.target.value as string[])}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {(selected as string[]).map((value) => {
                  const option = options.find((opt) => opt.value === value)
                  if (!option) return <span key={value}></span>
                  return <Chip key={value} label={option.label} className={classes.chip} />
                })}
              </div>
            )}>
            {options.map(({ label, value }) => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={!!values.find((selected) => selected === value)} />
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export const MultipleSelectForm = withStyles(styles)(_MultipleSelectForm)

// CheckBoxForm --------------

type CheckBoxFormProps = WithStyles<typeof styles> & {
  label: string
  options: { label: string; value: string; checked: boolean }[]
  onChange: (checkedValues: string[]) => void
}

const _CheckBoxForm = (props: CheckBoxFormProps) => {
  const { classes, label, options, onChange } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const items: string[] = []
    options.forEach(({ value, checked }) => {
      if (value === event.target.name ? event.target.checked : checked) items.push(value)
    })
    onChange(items)
  }

  return (
    <Grid container direction="row" alignItems="center" className={classes.formContainer}>
      <Grid item>
        <Typography align="right" className={classes.label}>
          {label}：
        </Typography>
      </Grid>
      <Grid item>
        {options.map(({ label, value, checked }) => (
          <FormControlLabel
            key={value}
            control={
              <Checkbox
                color="primary"
                checked={checked}
                onChange={handleChange}
                name={`${value}`}
              />
            }
            label={
              <Typography variant="body2">{label}</Typography> 
            }
          />
        ))}
      </Grid>
    </Grid>
  )
}

export const CheckBoxForm = withStyles(styles)(_CheckBoxForm)

// CheckBoxForm Single --------------

type CheckBoxSingleFormProps = WithStyles<typeof styles> & {
  option: { label: string; value: string; checked: boolean }
  onChange: (checkedValues: boolean) => void
}

const _CheckBoxSingleForm = (props: CheckBoxSingleFormProps) => {
  const { option, onChange } = props

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={option.checked}
          onChange={handleChange}
          name={`${option.value}`}
        />
      }
      label={option.label}
    />
  )
}

export const CheckBoxSingleForm = withStyles(styles)(_CheckBoxSingleForm)

// PeriodForm --------------

type NullMoment = moment.Moment | null
type PeriodFormProps = WithStyles<typeof styles> & {
  label: string
  withTime?: boolean
  from: NullMoment
  to: NullMoment
  minDate?: moment.Moment
  onChange: (from: NullMoment, to: NullMoment) => void
}

const _PeriodForm = (props: PeriodFormProps) => {
  const { classes, label, withTime, from, to, minDate, onChange } = props

  return (
    <MuiPickersUtilsProvider utils={DateMomentUtils}>
      <Grid container direction="row" alignItems="center" className={classes.formContainer}>
        <Grid item>
          <Typography align="right" className={classes.label}>
            {label}：
          </Typography>
        </Grid>
        <Grid item>
          {withTime ? (
            <KeyboardDateTimePicker
              className={classes.picker}
              disableToolbar
              variant="inline"
              margin="normal"
              format="YYYY/MM/DD HH:mm"
              value={from}
              onChange={(date) => onChange(date, to)}
            />
          ) : (
            <KeyboardDatePicker
              className={classes.picker}
              disableToolbar
              variant="inline"
              format="YYYY/MM/DD"
              margin="normal"
              minDate={minDate}
              value={from}
              onChange={(date) => onChange(date, to)}
            />
          )}
        </Grid>
        <Grid item>
          <Typography className={classes.periodSeparator}>〜</Typography>
        </Grid>
        {withTime ? (
          <Grid item>
            <KeyboardDateTimePicker
              className={classes.picker}
              disableToolbar
              variant="inline"
              format="YYYY/MM/DD HH:mm"
              margin="normal"
              value={to}
              onChange={(date) => onChange(from, date)}
            />
          </Grid>
        ) : (
          <KeyboardDatePicker
            className={classes.picker}
            disableToolbar
            variant="inline"
            format="YYYY/MM/DD"
            margin="normal"
            minDate={from || minDate}
            value={to}
            onChange={(date) => onChange(from, date)}
          />
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export const PeriodForm = withStyles(styles)(_PeriodForm)

// FormContainer --------------

type ContainerProps = PropsWithChildren<WithStyles<typeof styles>>

const _FormContainer = (props: ContainerProps) => {
  const { classes, children } = props

  return (
    <Paper square elevation={0} className={classes.container}>
      <Grid container>{children}</Grid>
    </Paper>
  )
}

export const FormContainer = withStyles(styles)(_FormContainer)
