import React from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {
  withStyles,
  WithStyles,
  Typography,
  Grid,
  Paper,
  Theme,
  createStyles,
} from '@material-ui/core'
import { GraphsState } from 'redux/modules/graphs'
import { TrendType } from 'utils/trendType'
import Graph from './Graph'
import TrendLabel from './TrendLabel'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
    },
    title: {
      fontSize: 17,
      fontWeight: 'bold',
    },
    trendContainer: {
      height: '100%',
    },
    graph: {
      width: 'calc(100% - 10px)',
      height: 300,
      margin: 5,
    },
  })

type HeartRateGraphProps = WithStyles<typeof styles> & {
  className?: string
  graphs: GraphsState
}

const HeartRateGraph = (props: HeartRateGraphProps) => {
  const { classes, className, graphs } = props
  if (!graphs.data) return <></>
  const { type, from, to } = graphs.trend
  const { heartRates } = graphs.data

  const values: {
    label: string | null
    min: number | null
    max: number | null
    average: number | null
  }[] = []

  let minData: { label: string; value: number } | null = null
  let maxData: { label: string; value: number } | null = null
  let sum = 0
  let count = 0

  const heartRateFor = (date: moment.Moment) =>
    heartRates.find((heartRate) => heartRate.date.unix() === date.unix())

  let date = moment(from)
  while (date < to) {
    const heartRate = heartRateFor(date)

    values.push({
      label: TrendType.graphLabel(type, date),
      min: heartRate ? heartRate.minValue : null,
      max: heartRate ? heartRate.maxValue : null,
      average: heartRate ? heartRate.averageValue : null,
    })

    const nextDate = moment(date).add(1, TrendType.graphUnit(type))

    if (heartRate) {
      if (!minData || minData.value > heartRate.averageValue)
        minData = {
          label: TrendType.trendLabel(type, date, nextDate),
          value: Math.round(heartRate.averageValue),
        }
      if (!maxData || maxData.value < heartRate.averageValue)
        maxData = {
          label: TrendType.trendLabel(type, date, nextDate),
          value: Math.round(heartRate.averageValue),
        }
      sum += heartRate.averageValue
      count += 1
    }

    date = nextDate
  }

  const averageData = count > 0 && {
    label: TrendType.trendLabel(type, from, to, true),
    value: Math.round(sum / count),
  }

  return (
    <Paper className={clsx(classes.container, className)}>
      <Typography className={classes.title}>心拍数</Typography>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Grid container direction="column" justify="center" className={classes.trendContainer}>
            <Grid item>
              {maxData && (
                <TrendLabel label="最大値" heartRate={maxData.value} date={maxData.label} />
              )}
            </Grid>
            <Grid item>
              {minData && (
                <TrendLabel label="最小値" heartRate={minData.value} date={minData.label} />
              )}
            </Grid>
            <Grid item>
              {averageData && (
                <TrendLabel label="平均値" heartRate={averageData.value} date={averageData.label} />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Graph
            className={classes.graph}
            isShowValueLine={type !== TrendType.Year}
            values={values}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(HeartRateGraph)
