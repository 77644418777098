import { createMuiTheme } from '@material-ui/core'
import colors, { SideBarColor } from 'utils/Color'

let theme = createMuiTheme({
  palette: {
    primary: {
      light: colors.Gray,
      main: colors.DarkGray,
      dark: colors.Black,
    },
    background: {
      default: colors.Background,
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
})

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: SideBarColor.Background,
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiTypography: {
      root: {
        color: colors.DarkGray,
      },
    },
  },
}

export const whiteTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: '#F7F7F7',
      contrastText: colors.Black,
    },
  },
})

export const blueTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: colors.Blue,
    },
  },
})

export const greenTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: colors.Green,
      contrastText: colors.White,
    },
  },
})

export default theme
