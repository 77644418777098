import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'

import {
  WithStyles,
  Grid,
  Typography,
  withStyles,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
} from '@material-ui/core'
import {
  MainContainer,
  SectionHeader,
  FormContainer,
  Form,
  PeriodForm,
  GreenButton,
} from 'components'

import validator from 'utils/validator'
import { deepCopy } from 'utils/deepCopy'
import { Platform } from 'utils/platform'
import { getLabelForKey, UserGroupConfig, UserGroupConfigKey } from 'redux/models/userGroup'

import { EditCompanyStoreStates, EditCompanyActions } from '.'
import styles from './styles'

type EditCompanyProps = RouteComponentProps<{ id: string }> &
  WithStyles<typeof styles> &
  EditCompanyStoreStates &
  EditCompanyActions

interface EditCompanyStates {
  initialization: boolean
  name: string
  code: string
  availableFrom: moment.Moment | null
  availableTo: moment.Moment | null
  configs: UserGroupConfig | null
}

class EditCompany extends React.Component<EditCompanyProps, EditCompanyStates> {
  constructor(props: EditCompanyProps) {
    super(props)

    this.state = {
      initialization: false,
      name: '',
      code: '',
      availableFrom: null,
      availableTo: null,
      configs: null,
    }

    props.fetchList()
  }

  get userGroup() {
    const { match, userGroups } = this.props
    const groupId = parseInt(match.params.id)
    return userGroups.items.find((item) => item.id === groupId)
  }

  componentDidUpdate(prevProps: EditCompanyProps) {
    const { initialization } = this.state
    const { history, userGroups } = this.props
    const group = this.userGroup

    {
      // update
      const currentValue = userGroups.update.updating
      const prevValue = prevProps.userGroups.update.updating

      if (prevValue && !currentValue && !userGroups.update.error) {
        // 更新が正常終了したら戻る
        history.goBack()
      }
    }

    {
      // update config
      const currentValue = userGroups.updateConfig.updating
      const prevValue = prevProps.userGroups.updateConfig.updating

      if (prevValue && !currentValue && !userGroups.updateConfig.error) {
        // 更新が正常終了したら戻る
        history.goBack()
      }
    }

    if (!initialization && group) {
      setTimeout(
        () =>
          this.setState({
            initialization: true,
            name: group.name,
            code: group.code,
            availableFrom: group.availableFrom,
            availableTo: group.availableTo,
            configs: deepCopy(group.configs) ?? null,
          }),
        200,
      )
    }
  }

  onSubmit() {
    const { name, code, availableFrom, availableTo } = this.state
    const group = this.userGroup

    if (!group) return

    try {
      validator.notEmpty(name, code)
      validator.groupCode(code)
    } catch (err) {
      alert(err)
      return
    }

    this.props.updateCompany({
      name,
      code,
      availableFrom: availableFrom?.startOf('day') || null,
      availableTo: availableTo?.startOf('day') || null,
      group,
    })
  }

  onSubmitConfigs() {
    const { configs } = this.state
    const group = this.userGroup
    if (!group || !configs) return

    this.props.updateConfig({ group, configs })
  }

  render() {
    const { name, code, availableFrom, availableTo, configs } = this.state
    const { classes, userGroups } = this.props
    const { update, updateConfig } = userGroups
    const isEmpty = [name, code].includes('')
    const group = this.userGroup

    if (!group || !name) return <></>

    return (
      <MainContainer>
        <SectionHeader>企業編集</SectionHeader>
        <FormContainer>
          <Form value={name} label="企業名" onChange={(name) => this.setState({ name })} />
          <Form
            value={code}
            label="企業コード"
            caption="変更すると所属ユーザーのセッションが切れます。"
            onChange={(code) => this.setState({ code })}
          />
          <PeriodForm
            from={availableFrom}
            to={availableTo}
            label="利用可能期間"
            minDate={moment().startOf('day')}
            onChange={(from, to) =>
              this.setState({
                availableFrom: from,
                availableTo: to,
              })
            }
          />
          <Grid container direction="column" alignItems="flex-end" spacing={1}>
            {update.error && (
              <Grid>
                <Typography color="secondary">{update.error}</Typography>
              </Grid>
            )}
            <Grid item>
              <GreenButton
                disabled={isEmpty || update.updating}
                variant="contained"
                title={update.updating ? '更新中' : '更新する'}
                onClick={() => this.onSubmit()}
              />
            </Grid>
          </Grid>
        </FormContainer>

        {configs && (
          <>
            <SectionHeader>企業設定</SectionHeader>

            <FormContainer>
              <TableContainer className={classes.tableContainer}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell align="center">iOS</TableCell>
                      <TableCell align="center">Android</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.values(UserGroupConfigKey).map((key) => (
                      <TableRow key={key}>
                        <TableCell>{getLabelForKey(key)}</TableCell>
                        {Object.values(Platform).map((platform) => (
                          <TableCell key={platform} align="center">
                            <Checkbox
                              checked={configs[key][platform] === '1'}
                              onChange={(_, checked) => {
                                configs[key][platform] = checked ? '1' : '0'
                                this.setState({ configs })
                              }}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid container direction="column" alignItems="flex-end" spacing={1}>
                {updateConfig.error && (
                  <Grid>
                    <Typography color="secondary">{updateConfig.error}</Typography>
                  </Grid>
                )}
                <Grid item>
                  <GreenButton
                    disabled={updateConfig.updating}
                    variant="contained"
                    title={updateConfig.updating ? '更新中' : '更新する'}
                    onClick={() => this.onSubmitConfigs()}
                  />
                </Grid>
              </Grid>
            </FormContainer>
          </>
        )}
      </MainContainer>
    )
  }
}

export default withStyles(styles)(EditCompany)
