import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as observationsActions, ObservationsState } from 'redux/modules/observations'
import { actions as userGroupsActions, UserGroupsState } from 'redux/modules/userGroups'
import { actions as usersActions, UsersState } from 'redux/modules/users'
import User from 'redux/models/user'
import Screen from './Users'

export interface UsersActions {
  fetchUserGroupList: () => Action<void>
  fetchUserList: () => Action<void>
  deleteUser: (user: User) => Action<User>
  resetObservations: (user: User) => Action<User>
}

export interface UsersStoreStates {
  observations: ObservationsState
  userGroups: UserGroupsState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): UsersActions {
  return {
    fetchUserGroupList: () => dispatch(userGroupsActions.fetchList()),
    fetchUserList: () => dispatch(usersActions.fetchList()),
    deleteUser: (user) => dispatch(usersActions.deleteUser(user)),
    resetObservations: (user) => dispatch(observationsActions.resetObservations(user)),
  }
}

function mapStateToProps(appState: AppState): UsersStoreStates {
  return {
    observations: appState.observations,
    userGroups: appState.userGroups,
    users: appState.users,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
