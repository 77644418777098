import { createStyles, Theme } from '@material-ui/core'
import { buildStyles } from 'react-circular-progressbar'
import colors from 'utils/Color'
import { Condition } from 'redux/models/userStatus'

export const stressCircleStyle = buildStyles({
  textColor: colors.White,
  textSize: 30,
  pathColor: colors.White,
  trailColor: colors.whiteWithAlpha(0.3),
})

export default (theme: Theme) =>
  createStyles({
    boldText: {
      fontWeight: 'bold',
    },

    headCell: {
      color: colors.LightGray,
      fontSize: 10,
      fontWeight: 'bold',
      borderBottom: 'none',
    },

    cell: {
      minHeight: 50,
      padding: 10,
      backgroundColor: theme.palette.common.white,
      borderBottom: `${theme.spacing(1)}px solid ${colors.Background}`,
      borderRight: `${theme.spacing(1)}px solid ${colors.Background}`,
    },

    iconCell: {
      width: 90,
      height: 90,
      padding: 0,
      borderBottom: `${theme.spacing(1)}px solid ${colors.Background}`,
      borderRight: `${theme.spacing(1)}px solid ${colors.Background}`,
      backgroundColor: theme.palette.common.white,
    },

    [`alertCell${Condition.Normal}`]: {
      backgroundColor: colors.White,
    },
    [`alertCell${Condition.Warning}`]: {
      backgroundColor: colors.ConditionYellow,
    },
    [`alertCell${Condition.Danger}`]: {
      backgroundColor: colors.ConditionRed,
    },

    [`conditionCell${Condition.Normal}`]: {
      backgroundColor: colors.ConditionGreen,
    },
    [`conditionCell${Condition.Warning}`]: {
      backgroundColor: colors.ConditionYellow,
    },
    [`conditionCell${Condition.Danger}`]: {
      backgroundColor: colors.ConditionRed,
    },

    iconCellText: {
      fontSize: 15,
      textAlign: 'center',
      color: theme.palette.common.white,
    },

    stressCell: {
      padding: theme.spacing(2),
    },
    [`drowsinessCell${Condition.Normal}`]: {
      backgroundColor: colors.ConditionGreen,
    },
    [`drowsinessCell${Condition.Warning}`]: {
      backgroundColor: colors.ConditionRed,
    },
    [`drowsinessCell${Condition.Danger}`]: {
      backgroundColor: colors.ConditionRed,
    },
    graphCell: {
      width: 100,
    },

    noDrowsinessIcon: {
      opacity: 0.3,
    },

    nameCellText: {
      fontSize: 17,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    alertCellText: {
      padding: 2,
      color: theme.palette.common.white,
      fontSize: 13,
      fontWeight: 'bold',
      textAlign: 'center',
    },

    cell_disenable: {
      backgroundColor: colors.LightGray,
    },
  })
