const Color = {
  White: '#FFFFFF',
  LightGray: '#A0A4A4',
  Gray: '#5E6564',
  Black: '#333333',
  DarkGray: '#555555',
  Red: '#DD1F1F',
  // Green: '#2AC81C',
  Green: '#9CC62C',
  Purple: '#7963FF',
  SalmonRed: '#df5656',
  LightGreen: '#C5EA5A',
  Blue: '#257CFF',
  Background: '#F0F0F0',
  ConditionYellow: '#F0D125',
  ConditionGreen: '#9CC62C',
  ConditionRed: '#FF4A65',
  whiteWithAlpha: (alpha: number) => `rgba(255,255,255,${alpha})`,
  grayscale: (white: number, alpha: number) =>
    `rgba(${255 * white},${255 * white},${255 * white},${alpha})`,
}

export const SideBarColor = {
  Background: '#252E2E',
  BoxActive: '#3B4443',
  BoxUnderLine: '#464E4D',
  headerText: 'gray',
  BoxText: '#464E4D',
  BoxActiveText: Color.LightGreen,
}

export const pinColors = {
  Gray: '#898989',
  Red: '#DD1F1F',
  Blue: '#4B7EFF',
  Orange: '#F37B24',
  Yellow: '#E0B620',
}

export default Color
