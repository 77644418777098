import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as userGroupsActions, UserGroupsState } from 'redux/modules/userGroups'
import { actions as usersActions, UsersState, AddUsersPayload } from 'redux/modules/users'
import Screen from './NewUser'

export interface NewUserActions {
  fetchUserGroupList: () => Action<void>
  importNewUserData: (file: File) => Action<File>
  addUsers: (payload: AddUsersPayload) => Action<AddUsersPayload>
}

export interface NewUserStoreStates {
  userGroups: UserGroupsState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): NewUserActions {
  return {
    fetchUserGroupList: () => dispatch(userGroupsActions.fetchList()),
    importNewUserData: (file) => dispatch(usersActions.importNewUserData(file)),
    addUsers: (payload) => dispatch(usersActions.addUsers(payload)),
  }
}

function mapStateToProps(appState: AppState): NewUserStoreStates {
  return { userGroups: appState.userGroups, users: appState.users }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
