import { createStyles, Theme } from '@material-ui/core'
import colors from 'utils/Color'

export default (theme: Theme) =>
  createStyles({
    cell: {
      width: 80,
      height: 80,
      padding: 0,
      borderBottom: `${theme.spacing(1)}px solid ${colors.Background}`,
      borderRight: `${theme.spacing(1)}px solid ${colors.Background}`,
    },
    approveCell: {
      backgroundColor: colors.Green,
    },
    rejectCell: {
      backgroundColor: colors.Red,
    },
    cellButton: {
      width: '100%',
      height: '100%',
      borderRadius: 0,
      color: theme.palette.common.white,
    },
    cellButtonText: {
      color: theme.palette.common.white,
      fontSize: 10,
    },
  })
