import { COOKIE_EXPIREDAYS, COOKIE_KEYS, DEFAULT_NOTIFICATIONS_MAP_LOCATION } from 'utils/Constants'
import Cookie from 'utils/Cookie'
import { notificationTypeTexts, NotificationType } from 'redux/models/notification'
import moment from 'moment'

interface NotificationsMapType {
  currentLocation: {
    lat: number
    lng: number
  }
  filter: {
    fromDate: moment.Moment
    toDate: moment.Moment
    userIds: number[]
    types: NotificationType[]
    limit: number
    locationName: string
  }
  addressData: {
    level: number
  },
  map: {
    zoom: number
  }
}

export const initialValues: () => NotificationsMapType = () => ({
  currentLocation: DEFAULT_NOTIFICATIONS_MAP_LOCATION,
  filter: {
    fromDate: moment().startOf('day'),
    toDate: moment(),
    userIds: [],
    types: Object.keys(notificationTypeTexts) as NotificationType[],
    limit: 300,
    locationName: "東京都千代田区丸の内１丁目",
  },
  addressData: {
    level: 3
  },
  map: {
    zoom: 15
  }
})

export function getItem() {
  const data = Cookie.getItem(COOKIE_KEYS.NOTIFICATIONS_MAP) as NotificationsMapType

  if(!data) return initialValues()

  const res = {
    ...data,
    filter: {
      ...data.filter,
      fromDate: moment(data.filter.fromDate),
      toDate: moment(data.filter.toDate),  
    },
    map: data.map || initialValues().map
  }
  
  return res
}

export function removeItem() {
  Cookie.removeItem(COOKIE_KEYS.NOTIFICATIONS_MAP);
}

export function setItem(notificationsMapType: NotificationsMapType) {
  Cookie.setItem(COOKIE_KEYS.NOTIFICATIONS_MAP, notificationsMapType, COOKIE_EXPIREDAYS);
}

export default {
  getItem,
  removeItem,
  setItem,
}