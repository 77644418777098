import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState, LogInPayload } from 'redux/modules/auth'
import Screen from './Login'

export interface LoginActions {
  logIn: (payload: LogInPayload) => Action<LogInPayload>
}

export interface LoginStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): LoginActions {
  return {
    logIn: (payload) => dispatch(authActions.logIn(payload)),
  }
}

function mapStateToProps(appState: AppState): LoginStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
