import React from 'react'
import { CssBaseline, WithStyles, withStyles, Hidden } from '@material-ui/core'

import { AppTemplateActions, AppTemplateStoreStates } from '.'
import { drawerWidth, templateStyles } from './styles'
import Navigator from './Navigator'

type AppTemplateProps = React.PropsWithChildren<
  WithStyles<typeof templateStyles> & AppTemplateActions & AppTemplateStoreStates
>

type AppTemplateStates = {
  isMenuOpen: boolean
}

class AppTemplate extends React.Component<AppTemplateProps, AppTemplateStates> {
  constructor(props: AppTemplateProps) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }

    props.fetchObservationRequests()
  }

  onHandleDrawerToggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  render() {
    const { isMenuOpen } = this.state
    const { classes, children, auth, observations } = this.props
    const { items } = observations.observationRequests
    const { user } = auth

    if (!user) return <></>

    return (
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={isMenuOpen}
              onClose={() => this.onHandleDrawerToggle()}
              user={user}
              numberOfObservationRequests={items.length}
              onMenuClick={() => this.onHandleDrawerToggle()}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator
              user={user}
              numberOfObservationRequests={items.length}
              PaperProps={{ style: { width: drawerWidth } }}
            />
          </Hidden>
        </nav>
        <div className={classes.app}>{children}</div>
      </div>
    )
  }
}

export default withStyles(templateStyles)(AppTemplate)
