import React from 'react'
import moment from 'moment'
import { Button, withStyles, WithStyles, Grid, Typography } from '@material-ui/core'

import { SERVICE_LAUNCHED_AT } from 'utils/Constants'
import CalendarButton from './CalendarButton'
import { weekStyles } from './styles'

type WeeksProps = WithStyles<typeof weekStyles> & {
  activeDate: moment.Moment
  onChange: (from: moment.Moment, to: moment.Moment) => void
}

interface WeeksStates {
  offset: number
}

class Weeks extends React.Component<WeeksProps, WeeksStates> {
  constructor(props: WeeksProps) {
    super(props)
    this.state = { offset: 0 }
  }

  render() {
    const { offset } = this.state
    const { classes, activeDate, onChange } = this.props

    const launchedDate = moment(SERVICE_LAUNCHED_AT).startOf('isoWeek')
    const baseDate = moment(activeDate).startOf('isoWeek')
    const offsetDate = moment(activeDate).startOf('month').add(offset, 'month')
    const months = [offsetDate, moment(offsetDate).add(1, 'month')]

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item container direction="row" alignItems="center">
          <Button
            className={classes.slideButton}
            disabled={offsetDate.isBefore(launchedDate)}
            onClick={() => this.setState({ offset: offset - 1 })}>
            ＜
          </Button>
          {months.map((month, index) => (
            <Grid item xs key={index}>
              <Typography align="center">{month.format('YYYY年MM月')}</Typography>
            </Grid>
          ))}
          <Button
            className={classes.slideButton}
            disabled={offsetDate.isSameOrAfter(moment().startOf('month'))}
            onClick={() => this.setState({ offset: offset + 1 })}>
            ＞
          </Button>
        </Grid>
        <Grid container direction="row">
          {months.map((month, mi) => {
            const date = moment(month).startOf('isoWeek')
            const weeks = [...Array(6)].map((_, wi) => {
              if (wi === 5 && moment(date).add(wi, 'week').month() !== month.month()) return null
              return [...Array(7)].map((_, di) => moment(date).add(wi, 'week').add(di, 'day'))
            })

            return (
              <Grid item xs container direction="column" key={mi}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className={classes.weekdayContainer}>
                  {['月', '火', '水', '木', '金', '土', '日'].map((label, index) => (
                    <Grid item xs key={index} className={classes.labelContainer}>
                      <Typography align="center" className={classes.weekdayLabel}>
                        {label}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
                {weeks.map((week, wi) => {
                  if (!week)
                    return (
                      <Grid item xs container direction="row" key={wi}>
                        <Grid item xs className={classes.button} />
                      </Grid>
                    )

                  const startDate = week[0]
                  return (
                    <Grid item xs container direction="row" key={wi}>
                      <CalendarButton
                        className={classes.button}
                        selected={startDate.isSame(baseDate)}
                        disabled={startDate.isBefore(launchedDate) || startDate.isAfter(moment())}
                        onClick={() => onChange(startDate, moment(startDate).endOf('isoWeek'))}>
                        <Grid container direction="row" justify="space-between">
                          {week.map((day, di) => (
                            <Grid item xs key={di} className={classes.labelContainer}>
                              {day.date() === 1 && (
                                <Typography className={classes.monthLabel}>
                                  {day.format('M/')}
                                </Typography>
                              )}
                              <Typography align="center" className={classes.dayLabel}>
                                {day.format('D')}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </CalendarButton>
                    </Grid>
                  )
                })}
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(weekStyles)(Weeks)
