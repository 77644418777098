import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as userGroupsActions,
  UserGroupsState,
  UpdateUserGroupPayload,
  UpdateUserGroupConfigPayload,
} from 'redux/modules/userGroups'
import Screen from './EditCompany'

export interface EditCompanyActions {
  fetchList: () => Action<void>
  updateCompany: (payload: UpdateUserGroupPayload) => Action<UpdateUserGroupPayload>
  updateConfig: (payload: UpdateUserGroupConfigPayload) => Action<UpdateUserGroupConfigPayload>
}

export interface EditCompanyStoreStates {
  userGroups: UserGroupsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): EditCompanyActions {
  return {
    fetchList: () => dispatch(userGroupsActions.fetchList()),
    updateCompany: (payload) => dispatch(userGroupsActions.updateUserGroup(payload)),
    updateConfig: (payload) => dispatch(userGroupsActions.updateUserGroupConfig(payload)),
  }
}

function mapStateToProps(appState: AppState): EditCompanyStoreStates {
  return { userGroups: appState.userGroups }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
