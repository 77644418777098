import { createStyles, Theme } from '@material-ui/core'
import colors from 'utils/Color'

export default (theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      background: colors.Background,
    },
    contentContainer: {
      width: 400,
      padding: theme.spacing(3),
      backgroundColor: colors.White,
      borderRadius: 10,
    },
    title: {
      textAlign: 'center',
      fontSize: 25,
      fontWeight: 'bold',
    },
    error: {
      color: colors.Red,
      textAlign: 'center',
      fontSize: 15,
    },
    button: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
  })
