import React from 'react'
import { RouteComponentProps } from 'react-router'

import Observation, { ObservationStatus, statusTexts } from 'redux/models/observation'
import { ObservationsActions, ObservationsStoreStates } from '.'

import { TableCell, Button, Grid, Typography, WithStyles, withStyles } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import { MainContainer, SectionHeader, FormContainer, Form, SelectForm, Table } from 'components'
import { AccessoryCell } from 'components/Table'

import styles from './styles'

type ObservationsProps = RouteComponentProps &
  WithStyles<typeof styles> &
  ObservationsActions &
  ObservationsStoreStates

interface ObservationsStates {
  name: string
  code: string
  status: ObservationStatus
}

class Observations extends React.Component<ObservationsProps, ObservationsStates> {
  constructor(props: ObservationsProps) {
    super(props)

    this.state = {
      name: '',
      code: '',
      status: ObservationStatus.None,
    }

    props.fetchObservations()
  }

  componentDidUpdate(prevProps: ObservationsProps) {
    const { observations, fetchObservations } = this.props
    const current = observations.request
    const previous = prevProps.observations.request

    if (previous.observation && !current.observation) {
      fetchObservations()

      // 監視情報の申請が終了したらアラート
      alert(current.error || `${previous.observation.name}に閲覧申請を送信しました`)
    }
  }

  onPressAction(item: Observation) {
    if (!item.isNoneStatus) return
    this.props.requestObservation(item)
  }

  renderObservationRequestCell(item: Observation) {
    const { classes } = this.props

    if (!item.isNoneStatus) return <></>

    return (
      <TableCell key={`request-${item.id}`} className={classes.requestCell}>
        <Button className={classes.cellButton} onClick={() => this.onPressAction(item)}>
          <Grid container direction="column">
            <Grid>
              <PersonAdd fontSize="large" />
            </Grid>
            <Grid>
              <Typography className={classes.cellButtonText}>申請</Typography>
            </Grid>
          </Grid>
        </Button>
      </TableCell>
    )
  }

  render() {
    const { name, code, status } = this.state
    const { auth, observations } = this.props
    const { items, fetched, fetching } = observations.observations
    const currentUser = auth.user

    if (!currentUser || fetching || !fetched) return <></>

    const options = Object.values(ObservationStatus).map((status) => ({
      label: statusTexts[status],
      value: status,
    }))

    const filteredItems = items.filter(
      (item) =>
        item.id !== currentUser.id &&
        item.name.includes(name) &&
        item.code.includes(code) &&
        item.status === status,
    )
    const rows = filteredItems.map((item) => [item.name, item.code, item.createdDate])
    const accessories: AccessoryCell[][] = filteredItems.map((item) => [
      this.renderObservationRequestCell(item),
    ])

    return (
      <MainContainer>
        <SectionHeader>絞り込み</SectionHeader>

        <FormContainer>
          <Form value={name} label="名前" onChange={(name) => this.setState({ name })} />
          <Form value={code} label="ユーザーID" onChange={(code) => this.setState({ code })} />
          <SelectForm
            value={`${status}`}
            label="対象ステータス"
            options={options}
            onChange={(value) => this.setState({ status: parseInt(value) as ObservationStatus })}
          />
        </FormContainer>

        <SectionHeader>検索結果</SectionHeader>

        <Table
          fetched
          headers={['名前', 'ユーザID', '登録日時', '']}
          rows={rows}
          accessories={accessories}
        />
      </MainContainer>
    )
  }
}

export default withStyles(styles)(Observations)
