import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as alertsActions, AlertsState } from 'redux/modules/alerts'
import Screen from './UsersAlertActions'

export interface UsersAlertActionsActions {
  fetchAlertActions: (userId: number) => Action<number>
}

export interface UsersAlertActionsStoreStates {
  alerts: AlertsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): UsersAlertActionsActions {
  return {
    fetchAlertActions: (userId) => dispatch(alertsActions.fetchAlertActions(userId)),
  }
}

function mapStateToProps(appState: AppState): UsersAlertActionsStoreStates {
  return { alerts: appState.alerts }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
