import moment from 'moment'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import Notification, { NotificationType, notificationTypeTexts } from 'redux/models/notification'
import { NormalizeResult } from '@geolonia/normalize-japanese-addresses'

// Actions
export const FETCH_NOTIFICATIONS = 'hamon/notification/FETCH_NOTIFICATIONS'
export const FETCH_NOTIFICATIONS_COMPETION = 'hamon/notification/FETCH_NOTIFICATIONS_COMPETION'
export const FETCH_MAP_NOTIFICATIONS = 'hamon/notification/FETCH_MAP_NOTIFICATIONS'
export const FETCH_MAP_NOTIFICATIONS_COMPETION =
  'hamon/notification/FETCH_MAP_NOTIFICATIONS_COMPETION'
export const FETCH_MAP_LOCATION_NOTIFICATIONS = 'hamon/notification/FETCH_MAP_LOCATION_NOTIFICATIONS'
export const FETCH_MAP_LOCATION_NOTIFICATIONS_COMPETION =
  'hamon/notification/FETCH_MAP_LOCATION_NOTIFICATIONS_COMPETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface FetchResultPayload {
  items: Notification[]
  count: number
}

export interface FetchResultPayloadForMap {
  items: Notification[]
  count: number
}

export interface FetchResultPayloadForMapLocation {
  addressData: NormalizeResult
}

export interface FetchForMapPayload {
  fromDate: moment.Moment
  toDate: moment.Moment
  userIds: number[]
  types: NotificationType[]
  limit: number
}

export interface FetchForMapLocationPayload {
  locationName: string
}

export const actions = {
  fetchNotifications: actionCreator<number>(FETCH_NOTIFICATIONS),
  completedToFetchNotifications: actionCreator<FetchResultPayload | null>(
    FETCH_NOTIFICATIONS_COMPETION,
  ),
  fetchNotificationsForMap: actionCreator<FetchForMapPayload>(FETCH_MAP_NOTIFICATIONS),
  completedToFetchNotificationsForMap: actionCreator<FetchResultPayload | null>(
    FETCH_MAP_NOTIFICATIONS_COMPETION,
  ),
  fetchNotificationsForMapLocation: actionCreator<FetchForMapLocationPayload>(FETCH_MAP_LOCATION_NOTIFICATIONS),
  completedToFetchNotificationsForMapLocation: actionCreator<FetchResultPayloadForMapLocation | null>(
    FETCH_MAP_LOCATION_NOTIFICATIONS_COMPETION,
  ),
}

// Reducer
export interface NotificationsState {
  list: {
    fetching: boolean
    fetched: boolean
    limit: number
    page: number
    items: Notification[]
    count: number
  }
  map: {
    fromDate: moment.Moment
    toDate: moment.Moment
    userIds: number[]
    types: NotificationType[]
    limit: number
    fetching: boolean
    fetched: boolean
    items: Notification[]
  }
  addressData: {
    level: number
    location: {
      lat: number
      lng: number
    }  
  }
}

export interface NotificationsMapLocationState {
  location: {
    lat: number,
    lng: number,
  }
}

const initialState: () => NotificationsState = () => ({
  list: {
    fetching: false,
    fetched: false,
    limit: 200,
    page: 1,
    items: [],
    count: 1,
  },
  map: {
    fromDate: moment().startOf('day'),
    toDate: moment(),
    userIds: [],
    types: Object.keys(notificationTypeTexts) as NotificationType[],
    limit: 300,
    fetching: false,
    fetched: false,
    items: [],
  },
  addressData: {
    level: 3,
    location: {
      lat: 0,
      lng: 0,
    }  
  }
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchNotifications, (state, page) => {
    const list = state.list

    return {
      ...state,
      list: {
        ...list,
        fetching: true,
        fetched: false,
        page,
        items: [],
        count: 1,
      },
    }
  })
  .case(actions.completedToFetchNotifications, (state, data) => {
    const list = state.list

    return {
      ...state,
      list: {
        ...list,
        fetching: false,
        fetched: true,
        items: (data && data.items) || [],
        count: (data && data.count) || 0,
      },
    }
  })
  .case(actions.fetchNotificationsForMap, (state, data) => {
    const map = state.map

    return {
      ...state,
      map: {
        ...map,
        ...data,
        fetching: true,
        fetched: false,
        items: [],
      },
    }
  })
  .case(actions.completedToFetchNotificationsForMap, (state, data) => {
    const map = state.map

    return {
      ...state,
      map: {
        ...map,
        fetching: false,
        fetched: true,
        items: (data && data.items) || [],
      },
    }
  })
  .case(actions.fetchNotificationsForMapLocation, (state, data) => {
    const map = state.map

    return {
      ...state,
      map: {
        ...map,
        ...data,
        fetching: false,
        fetched: false,
        items: map.items,
      },
    }
  })
  .case(actions.completedToFetchNotificationsForMapLocation, (state, data) => {
    const map = state.map

    let addressData = initialState().addressData
    if(data && data.addressData) {
      addressData.level = data.addressData.level

      if(data.addressData.lat && data.addressData.lng) {
        addressData = {
          level: data.addressData.level,
          location: {
            lat: data.addressData.lat,
            lng: data.addressData.lng,
          }
        }
      }
    }

    return {
      ...state,
      map: {
        ...map,
        fetching: false,
        fetched: true,
      },
      addressData: addressData
    }
  })
