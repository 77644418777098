import moment from 'moment'
import { formatDateTime } from 'utils/moment'
import { UserStatusData } from 'utils/apiClient'
import { ConditionIcon } from 'utils/Icon'

export const Condition = {
  Normal: 0,
  Warning: 1,
  Danger: 2,
} as const
export type Condition = typeof Condition[keyof typeof Condition]

export type AnalysisData = {
  value: number
  condition: Condition
  createdAt: string
}

export default class UserStatus {
  userId: number
  userName: string
  isConnecting: boolean
  heartRate: number | null
  alert: {
    count: number
    coreBodyTemperature: AnalysisData | null
    drowsiness: AnalysisData | null
    hyperthermia: AnalysisData | null
    stress: AnalysisData | null
  }

  analysis: {
    coreBodyTemperature: AnalysisData | null
    drowsiness: AnalysisData | null
    hyperthermia: AnalysisData | null
    stress: AnalysisData | null
  }

  isTransmitter: boolean

  get maxCondition() {
    return Math.max(
      this.analysis.hyperthermia?.condition || 0,
      this.analysis.stress?.condition || 0,
      this.analysis.coreBodyTemperature?.condition || 0,
      (this.analysis.drowsiness?.condition || 0) === 0 ? 0 : 2,
    ) as Condition
  }

  get alertTexts() {
    return [
      this.hyperthermiaAlert(this.analysis.hyperthermia?.condition),
      this.stressAlert(this.analysis.stress?.condition),
      this.coreBodyTemperatureAlert(this.analysis.coreBodyTemperature?.condition),
      this.drowsinessAlert(this.analysis.drowsiness?.condition),
    ].filter((text) => !!text)
  }

  get alertActionTexts() {
    return [
      this.alert.hyperthermia && {
        date: formatDateTime(moment(this.alert.hyperthermia.createdAt)),
        text: this.hyperthermiaAlert(this.alert.hyperthermia.condition),
      },
      this.alert.stress && {
        date: formatDateTime(moment(this.alert.stress.createdAt)),
        text: this.stressAlert(this.alert.stress.condition),
      },
      this.alert.coreBodyTemperature && {
        date: formatDateTime(moment(this.alert.coreBodyTemperature.createdAt)),
        text: this.coreBodyTemperatureAlert(this.alert.coreBodyTemperature.condition),
      },
      this.alert.drowsiness && {
        date: formatDateTime(moment(this.alert.drowsiness.createdAt)),
        text: this.drowsinessAlert(this.alert.drowsiness.condition),
      },
    ].filter((text) => !!text) as { date: string; text: string }[]
  }

  get hyperthermiaIcon() {
    switch (this.analysis.hyperthermia?.condition) {
      case Condition.Normal:
        return ConditionIcon.HyperthermiaGood
      case Condition.Warning:
        return ConditionIcon.HyperthermiaWarning
      case Condition.Danger:
        return ConditionIcon.HyperthermiaBad
      default:
        return null
    }
  }

  get coreBodyTemperatureIcon() {
    switch (this.analysis.coreBodyTemperature?.condition) {
      case Condition.Normal:
        return ConditionIcon.HeatGood
      case Condition.Warning:
        return ConditionIcon.HeatWarning
      case Condition.Danger:
        return ConditionIcon.HeatBad
      default:
        return null
    }
  }

  get coreBodyTemperatureText() {
    switch (this.analysis.coreBodyTemperature?.condition) {
      case Condition.Normal:
        return '正常'
      case Condition.Warning:
        return '注意'
      case Condition.Danger:
        return '警告'
      default:
        return ''
    }
  }

  get drowsinessIcon() {
    if (this.analysis.drowsiness === null) return null
    return ConditionIcon.Drowsiness
  }

  constructor(
    userId: number,
    userName: string,
    isConnecting = false,
    heartRate: number | null = null,
    alert: {
      count: number
      coreBodyTemperature: AnalysisData | null
      drowsiness: AnalysisData | null
      hyperthermia: AnalysisData | null
      stress: AnalysisData | null
    } = {
      count: 0,
      coreBodyTemperature: null,
      drowsiness: null,
      hyperthermia: null,
      stress: null,
    },
    analysis: {
      coreBodyTemperature: AnalysisData | null
      drowsiness: AnalysisData | null
      hyperthermia: AnalysisData | null
      stress: AnalysisData | null
    } = {
      coreBodyTemperature: null,
      drowsiness: null,
      hyperthermia: null,
      stress: null,
    },
    isTransmitter = true,
  ) {
    this.userId = userId
    this.userName = userName
    this.isConnecting = isConnecting
    this.heartRate = heartRate
    this.alert = alert
    this.analysis = analysis
    this.isTransmitter = isTransmitter
  }

  static load(data: UserStatusData): UserStatus {
    return new UserStatus(
      data.userId,
      data.userName,
      data.isConnecting,
      data.heartRate,
      data.alert,
      data.analysis,
      data.isTransmitter,
    )
  }

  static loadAll(data: UserStatusData[]): UserStatus[] {
    return data.map((item) => this.load(item))
  }

  private hyperthermiaAlert(condition?: number) {
    switch (condition) {
      case Condition.Normal:
        return ''
      case Condition.Warning:
        return '体調変化を検知しました。'
      case Condition.Danger:
        return '大きな体調変化を検知しました。'
      default:
        return ''
    }
  }

  private stressAlert(condition?: number) {
    switch (condition) {
      case Condition.Normal:
        return ''
      case Condition.Warning:
        return 'ストレスの上昇を検知しました。'
      case Condition.Danger:
        return 'ストレスの大きな上昇を検知しました。'
      default:
        return ''
    }
  }

  private coreBodyTemperatureAlert(condition?: number) {
    switch (condition) {
      case Condition.Normal:
        return ''
      case Condition.Warning:
        return '暑熱リスクを検知しました。'
      case Condition.Danger:
        return '高い暑熱リスクを検知しました。'
      default:
        return ''
    }
  }

  private drowsinessAlert(condition?: number) {
    return (condition || 0) === Condition.Normal ? '' : '過度なリラックスを検知しました。'
  }
}
