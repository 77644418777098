import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as notificationsActions,
  NotificationsState,
  FetchForMapPayload,
  FetchForMapLocationPayload,
} from 'redux/modules/notifications'
import { actions as observationsActions, ObservationsState } from 'redux/modules/observations'
import Screen from './NotificationsMap'

export interface NotificationsMapActions {
  fetchNotifications: (payload: FetchForMapPayload) => Action<FetchForMapPayload>
  fetchObservations: () => Action<void>
  fetchNotificationsMapLocation: (payload: FetchForMapLocationPayload) => Action<FetchForMapLocationPayload>
}

export interface NotificationsMapStoreStates {
  notifications: NotificationsState
  observations: ObservationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): NotificationsMapActions {
  return {
    fetchNotifications: (payload) =>
      dispatch(notificationsActions.fetchNotificationsForMap(payload)),
    fetchObservations: () => dispatch(observationsActions.fetchObservations()),
    fetchNotificationsMapLocation: (payload) => 
      dispatch(notificationsActions.fetchNotificationsForMapLocation(payload))
  }
}

function mapStateToProps(appState: AppState): NotificationsMapStoreStates {
  return {
    notifications: appState.notifications,
    observations: appState.observations,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
