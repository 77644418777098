import { StressData } from 'utils/apiClient'
import moment from 'moment'
import User from './user'
import { Condition } from './userStatus'

export default class Stress {
  id: number
  value: number
  condition: Condition
  date: moment.Moment
  user: User
  createdAt: moment.Moment

  constructor(
    id: number,
    value: number,
    condition: Condition,
    date: moment.Moment,
    user: User,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.value = value
    this.condition = condition
    this.date = date
    this.user = user
    this.createdAt = createdAt
  }

  static load(data: StressData): Stress {
    return new Stress(
      data.id,
      data.value,
      data.condition,
      moment.unix(data.timestamp),
      User.load(data.user),
      moment(data.createdAt),
    )
  }

  static loadAll(data: StressData[]): Stress[] {
    return data.map((item) => this.load(item))
  }
}
