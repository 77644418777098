import React from 'react'
import clsx from 'clsx'
import { RouteComponentProps } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'

import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import UserStatus, { Condition } from 'redux/models/userStatus'
import { AlertActionType, alertActionTypeText } from 'redux/models/alertAction'
import { DashboardActions, DashboardStoreStates } from '.'

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { ArrowRight, Warning, History } from '@material-ui/icons'
import { MainContainer, WhiteButton, Form, SelectForm } from 'components'
import Icon, { Icons } from 'utils/Icon'
import styles, { stressCircleStyle } from './styles'

type DashboardProps = RouteComponentProps &
  WithStyles<typeof styles> &
  DashboardActions &
  DashboardStoreStates

type DashboardStates = {
  alertAction: UserStatus | null
  actionType: AlertActionType
  actionText: string
}

class Dashboard extends React.Component<DashboardProps, DashboardStates> {
  intervalId = -1

  constructor(props: DashboardProps) {
    super(props)

    this.state = {
      alertAction: null,
      actionType: AlertActionType.EMAIL,
      actionText: '',
    }

    props.fetchObservations()
    props.fetchStatuses()
  }

  componentDidMount() {
    this.intervalId = setInterval(() => {
      const { observations, fetchStatuses } = this.props
      const { fetching } = observations.statuses
      if (fetching) return
      fetchStatuses()
    }, 1000 * 10)
  }

  componentDidUpdate(prevProps: DashboardProps) {
    const prevData = prevProps.alerts.sendingData
    const { sendingData, sendError } = this.props.alerts

    if (!prevData || sendingData) return

    alert(sendError || 'アクションを登録しました')
    this.props.fetchStatuses()
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  registerAlertAction() {
    const { alertAction, actionType, actionText } = this.state
    if (!alertAction || actionType === null || !actionText) return

    this.props.sendAlertAction({
      userId: alertAction.userId,
      type: actionType,
      message: actionText,
    })

    this.setState({
      alertAction: null,
      actionType: AlertActionType.EMAIL,
      actionText: '',
    })
  }

  renderNoContentComponent() {
    return (
      <Grid container direction="column">
        <Grid>
          <Typography variant="h3" align="center">
            表示できるユーザがいません。
          </Typography>
        </Grid>
        <Typography variant="h6" align="center">
          バイタル閲覧申請から追加してください。
        </Typography>
      </Grid>
    )
  }

  render() {
    const { alertAction, actionType, actionText } = this.state
    const { classes, history, observations } = this.props
    const { items, fetching } = observations.statuses
    const headers = ['名前', 'アラート', '心拍', '体調', 'ストレス', '暑熱リスク', '眠気', 'グラフ']

    const options = Object.values(AlertActionType).map((type) => ({
      label: alertActionTypeText(type),
      value: type,
    }))

    return (
      <MainContainer headerItem={<ClipLoader size={20} color={'#123abc'} loading={fetching} />}>
        <TableContainer>
          {!fetching && items.length === 0 && this.renderNoContentComponent()}
          {items.length > 0 && (
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell align="center" className={classes.headCell} key={index}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, ri) => {
                  return (
                    <TableRow key={ri}>
                      <TableCell className={classes.cell}>
                        <Grid container direction="column">
                          <Grid item container direction="row" spacing={1}>
                            <Grid item>
                              <Icon src={Icons.Connection} />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                {item.isConnecting ? '接続' : '未接続'}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs>
                            <Typography className={classes.nameCellText}>
                              {item.userName}
                            </Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={clsx(classes.cell, classes[`alertCell${item.maxCondition}`])}>
                        <Grid container direction="column">
                          <Grid item xs>
                            {item.alertTexts.map((text) => (
                              <Typography key={text} className={classes.alertCellText}>
                                {text}
                              </Typography>
                            ))}
                          </Grid>
                          <Grid item container direction="row" alignItems="flex-end" spacing={1}>
                            <Grid item xs />
                            {item.alert.count > 0 && (
                              <Grid item>
                                <WhiteButton
                                  variant="contained"
                                  size="small"
                                  startIcon={<Warning fontSize="small" />}
                                  title={`${item.alert.count}件`}
                                  onClick={() => this.setState({ alertAction: item })}
                                />
                              </Grid>
                            )}
                            <Grid item>
                              <WhiteButton
                                variant="contained"
                                size="small"
                                startIcon={<History fontSize="small" />}
                                title="アクション履歴"
                                onClick={() => history.push(`/users/${item.userId}/alert_actions`)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell className={classes.iconCell}>
                        <Typography align="center">
                          <Typography variant="h6" component="span" className={classes.boldText}>
                            {item.heartRate && item.heartRate > 0 ? item.heartRate : '-'}
                          </Typography>
                          <Typography variant="h6" component="span">
                            {' '}
                            拍/分
                          </Typography>
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.iconCell,
                          item.isTransmitter ? classes[`conditionCell${item.analysis.hyperthermia?.condition}`] : classes.cell_disenable,
                        )}>
                        <Grid container direction="column" alignItems="center">
                          {item.hyperthermiaIcon && <Icon src={item.hyperthermiaIcon} size={60} />}
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.iconCell,
                          classes[`conditionCell${item.analysis.stress?.condition}`],
                          classes.stressCell,
                        )}>
                        {item.analysis.stress && (
                          <CircularProgressbar
                            styles={stressCircleStyle}
                            maxValue={100}
                            value={item.analysis.stress.value}
                            text={`${item.analysis.stress.value}`}
                          />
                        )}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.iconCell,
                          classes[`conditionCell${item.analysis.coreBodyTemperature?.condition}`],
                        )}>
                        <Grid container direction="column" alignItems="center">
                          {item.coreBodyTemperatureIcon && (
                            <Icon src={item.coreBodyTemperatureIcon} size={40} />
                          )}
                        </Grid>
                        <Grid>
                          <Typography className={classes.iconCellText}>
                            {item.coreBodyTemperatureText}
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell
                        className={clsx(
                          classes.iconCell,
                          item.isTransmitter ? classes[`drowsinessCell${item.analysis.drowsiness?.condition}`] : classes.cell_disenable,
                        )}>
                        <Grid
                          container
                          direction="column"
                          alignItems="center"
                          className={clsx(
                            item.analysis.drowsiness?.condition === Condition.Normal &&
                              classes.noDrowsinessIcon,
                          )}>
                          {item.drowsinessIcon && <Icon src={item.drowsinessIcon} size={40} />}
                        </Grid>
                        <Grid>
                          <Typography className={classes.iconCellText}>
                            {item.analysis.drowsiness !== null &&
                              (item.analysis.drowsiness.condition === Condition.Normal
                                ? 'なし'
                                : 'あり')}
                          </Typography>
                        </Grid>
                      </TableCell>
                      <TableCell className={clsx(classes.iconCell, classes.graphCell)}>
                        <Button onClick={() => history.push(`/users/${item.userId}/graphs`)}>
                          <Grid container>
                            <Grid
                              item
                              xs
                              container
                              direction="column"
                              alignItems="center"
                              spacing={1}>
                              <Grid item>
                                <Icon src={Icons.Graph} size={30} />
                              </Grid>
                              <Grid item>
                                <Typography variant="caption">グラフを見る</Typography>
                              </Grid>
                            </Grid>
                            <Grid item xs={2} container direction="column" justify="center">
                              <ArrowRight />
                            </Grid>
                          </Grid>
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {alertAction && (
          <Dialog
            open={alertAction !== null}
            onClose={() => this.setState({ alertAction: null })}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">アラートアクションの登録</DialogTitle>
            <DialogContent>
              {alertAction.alertActionTexts.map(({ date, text }) => (
                <React.Fragment key={text}>
                  <Typography variant="body2">{date}</Typography>
                  <Typography variant="h6">{text}</Typography>
                </React.Fragment>
              ))}
              {alertAction.alertActionTexts.length < alertAction.alert.count && (
                <DialogContentText align="right">
                  ...他{alertAction.alert.count - alertAction.alertActionTexts.length}件
                </DialogContentText>
              )}

              <SelectForm
                value={`${actionType}`}
                label="アクション"
                options={options}
                onChange={(value) =>
                  this.setState({ actionType: parseInt(value) as AlertActionType })
                }
              />
              <Form
                value={actionText}
                label="内容"
                onChange={(actionText) => this.setState({ actionText })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ alertAction: null })} color="primary">
                キャンセル
              </Button>
              <Button onClick={() => this.registerAlertAction()} color="primary">
                登録
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </MainContainer>
    )
  }
}

export default withStyles(styles)(Dashboard)
