import { NotificationData } from 'utils/apiClient'
import moment from 'moment'
import { formatDateTime } from 'utils/moment'
import { pinColors } from 'utils/Color'
import User from './user'
import Location from './location'

export const NotificationType = {
  Others: 'others',
  CoreBodyTemperatureWarning: 'core_body_temperature_warning',
  CoreBodyTemperatureDanger: 'core_body_temperature_danger',
  Drowsiness: 'drowsiness',
  HyperthermiaWarning: 'hyperthermia_warning',
  HyperthermiaDanger: 'hyperthermia_danger',
  StressWarning: 'stress_warning',
  StressDanger: 'stress_danger',
  Tumble: 'tumble',
} as const
export type NotificationType = typeof NotificationType[keyof typeof NotificationType]

export const notificationTypeTexts = {
  // [NotificationType.Others]: 'その他',
  [NotificationType.CoreBodyTemperatureWarning]: '暑熱リスク(注意)',
  [NotificationType.CoreBodyTemperatureDanger]: '暑熱リスク(警告)',
  [NotificationType.Drowsiness]: '眠気',
  [NotificationType.HyperthermiaWarning]: '体調(注意)',
  [NotificationType.HyperthermiaDanger]: '体調(警告)',
  [NotificationType.StressWarning]: 'ストレス(注意)',
  [NotificationType.StressDanger]: 'ストレス(警告)',
  [NotificationType.Tumble]: '転倒',
}

export default class Notification {
  static NotificationType = NotificationType

  id: number
  type: NotificationType
  message: string
  user: User
  location: Location | null
  notifiedAt: moment.Moment

  get notifiedDate(): string {
    return formatDateTime(this.notifiedAt)
  }

  get pinColor(): string {
    switch (this.type) {
      case NotificationType.Others:
        return pinColors.Gray
      case NotificationType.CoreBodyTemperatureWarning:
      case NotificationType.CoreBodyTemperatureDanger:
        return pinColors.Yellow
      case NotificationType.Drowsiness:
        return pinColors.Blue
      case NotificationType.HyperthermiaWarning:
      case NotificationType.HyperthermiaDanger:
        return pinColors.Orange
      case NotificationType.StressWarning:
      case NotificationType.StressDanger:
        return pinColors.Red
      case NotificationType.Tumble:
        return pinColors.Gray
      default:
        return pinColors.Gray
    }
  }

  constructor(
    id: number,
    type: NotificationType,
    message: string,
    user: User,
    location: Location | null,
    notifiedAt: moment.Moment,
  ) {
    this.id = id
    this.type = type
    this.message = message
    this.user = user
    this.location = location
    this.notifiedAt = notifiedAt
  }

  static load(data: NotificationData): Notification {
    return new Notification(
      data.id,
      data.notificationType.name,
      data.message,
      User.load(data.user),
      (data.location && Location.load(data.location)) || null,
      moment.unix(data.timestamp),
    )
  }

  static loadAll(data: NotificationData[]): Notification[] {
    return data.map((item) => this.load(item))
  }
}
