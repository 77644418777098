import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Action } from 'typescript-fsa'

import { AppState } from 'redux/store'
import { AuthState, actions } from 'redux/modules/auth'

interface AuthLoadingActions {
  load: () => Action<void>
}

interface AuthLoadingProps {
  auth: AuthState
}

type OwnProps = React.PropsWithChildren<AuthLoadingActions & AuthLoadingProps>

class AuthLoading extends React.Component<OwnProps> {
  componentDidMount(): void {
    this.props.load()
  }

  render = (): React.ReactNode => {
    const { auth, children } = this.props
    const { loading, loaded } = auth

    // ユーザー読み込み待ち
    if (loading || !loaded) {
      return <div></div>
    }

    return <>{children}</>
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): AuthLoadingActions => {
  return {
    load: (): Action<void> => dispatch(actions.load()),
  }
}

const mapStateToProps = (state: AppState): AuthLoadingProps => ({
  auth: state.auth,
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthLoading)
