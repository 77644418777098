import React from 'react'
import clsx from 'clsx'
import { RouteComponentProps } from 'react-router'

import { Button, withStyles, WithStyles, Grid, Typography } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'

import { TrendType } from 'utils/trendType'

import { MainContainer, SectionHeader, CalendarDialog } from 'components'
import { UsersGraphsActions, UsersGraphsStoreStates } from '.'
import HeartRateGraph from './HeartRateGraph'
import HeatRiskGraph from './HeatRiskGraph'
import StressGraph from './StressGraph'
import styles from './styles'

type UsersGraphsProps = WithStyles<typeof styles> &
  RouteComponentProps<{ id: string }> &
  UsersGraphsActions &
  UsersGraphsStoreStates

interface UsersGraphsStates {
  userId: number
  isOpenCalendar: boolean
}

class UsersGraphs extends React.Component<UsersGraphsProps, UsersGraphsStates> {
  constructor(props: UsersGraphsProps) {
    super(props)

    const { match } = props
    const userId = parseInt(match.params.id)

    this.state = {
      userId,
      isOpenCalendar: false,
    }

    props.fetchGraphData(userId)
  }

  componentDidUpdate(prevProps: UsersGraphsProps) {
    const { userId } = this.state
    const { type: pType, from: pFrom, to: pTo } = prevProps.graphs.trend
    const { type, from, to } = this.props.graphs.trend

    if (pType !== type || pFrom !== from || pTo !== to) {
      this.props.fetchGraphData(userId)
    }
  }

  get dateLabel() {
    const { graphs } = this.props
    const { type, from, to } = graphs.trend

    switch (type) {
      case TrendType.Day:
        return from.format('YYYY年MM月DD日')
      case TrendType.Week:
        return from.format('YYYY年MM月DD日') + '〜' + to.format('MM月DD日')
      case TrendType.Month:
        return from.format('YYYY年MM月')
      case TrendType.Year:
        return from.format('YYYY年')
      default:
        return ''
    }
  }

  onChangeDateRange(from: moment.Moment, to: moment.Moment) {
    const { graphs, updateTrendRange } = this.props
    const { type } = graphs.trend
    this.setState({ isOpenCalendar: false })
    updateTrendRange({ type, from, to })
  }

  renderTrendButton(type: TrendType, label: string) {
    const { classes, graphs, changeTrendType } = this.props
    const { type: activeType } = graphs.trend
    return (
      <Button
        className={clsx(classes.dateButton, type === activeType && classes.dateButtonActive)}
        onClick={() => changeTrendType(type)}>
        {label}
      </Button>
    )
  }

  render() {
    const { isOpenCalendar } = this.state
    const { classes, graphs, switchToNextTrendRange, switchToPreviousTrendRange } = this.props
    const { type, from } = graphs.trend

    if (graphs.fetching)
      return (
        <MainContainer>
          <Typography variant="h5" component="p" align="center">
            データを取得中
          </Typography>
        </MainContainer>
      )

    if (!graphs.data)
      return (
        <MainContainer>
          <Typography variant="h5" component="p" align="center">
            データを取得できませんでした
          </Typography>
        </MainContainer>
      )

    return (
      <MainContainer>
        <SectionHeader>{graphs.data.user.name}のグラフ</SectionHeader>
        <Grid container direction="row">
          {this.renderTrendButton(TrendType.Day, '日')}
          {this.renderTrendButton(TrendType.Week, '週')}
          {this.renderTrendButton(TrendType.Month, '月')}
          {this.renderTrendButton(TrendType.Year, '年')}

          <Grid container item xs direction="row" justify="flex-end">
            <Button
              className={clsx(classes.dateButton)}
              onClick={() => switchToPreviousTrendRange()}>
              ＜
            </Button>
            <Button
              className={classes.calenderButton}
              onClick={() => this.setState({ isOpenCalendar: true })}>
              <DateRange fontSize="large" className={classes.calenderButtonIcon} />
              {this.dateLabel}
            </Button>
            <Button className={clsx(classes.dateButton)} onClick={() => switchToNextTrendRange()}>
              ＞
            </Button>
          </Grid>
        </Grid>
        <StressGraph className={classes.graphContainer} graphs={graphs} />
        <Grid container direction="column">
          <HeatRiskGraph className={classes.graphContainer} graphs={graphs} />
          <HeartRateGraph className={classes.graphContainer} graphs={graphs} />
        </Grid>
        <CalendarDialog
          open={isOpenCalendar}
          type={type}
          activeDate={from}
          onChange={(from, to) => this.onChangeDateRange(from, to)}
          onClose={() => this.setState({ isOpenCalendar: false })}
        />
      </MainContainer>
    )
  }
}

export default withStyles(styles)(UsersGraphs)
