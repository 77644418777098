import { createStyles, Theme } from '@material-ui/core'
import colors from 'utils/Color'
import { HTMLProps } from 'react'

export default (theme: Theme) =>
  createStyles({
    mapContainer: {
      width: '100%',
      height: 500,
      backgroundColor: colors.White,
      border: `2px solid ${colors.White}`,
    },
    infoWindowContainer: {
      position: 'absolute',
      bottom: 10,
      left: -140,
      width: 300,
      padding: theme.spacing(2),
      backgroundColor: colors.White,
      zIndex: 1000,
    },
    infoWindowCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    keepButton: {
      marginRight: 10
    },
    searchAddress_des: {
      paddingLeft: 110,
    },
    space: {
      marginLeft: 20,
      marginRight: 20,
    },
  })

export const pinStyle = (color: string): HTMLProps<HTMLDivElement> => ({
  color,
})
