import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import { actions as observationsActions, ObservationsState } from 'redux/modules/observations'
import AppTemplate from './AppTemplate'

export interface AppTemplateActions {
  fetchObservationRequests: () => Action<void>
}

export interface AppTemplateStoreStates {
  auth: AuthState
  observations: ObservationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): AppTemplateActions {
  return {
    fetchObservationRequests: () => dispatch(observationsActions.fetchObservationRequests()),
  }
}

function mapStateToProps(appState: AppState): AppTemplateStoreStates {
  return {
    auth: appState.auth,
    observations: appState.observations,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppTemplate)
