import { channel } from 'redux-saga'
import { put, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'

import CSVReader, { NewUserOutput } from 'utils/CSVReader'
import validator from 'utils/validator'
import apiClient, { UserData } from 'utils/apiClient'
import { AppState } from 'redux/store'
import * as Users from 'redux/modules/users'
import { UserGroupsState } from 'redux/modules/userGroups'
import User from 'redux/models/user'

type ImportNewUserDataAction = ReturnType<typeof Users.actions.importNewUserData>
type AddUsersAction = ReturnType<typeof Users.actions.addUsers>
type UpdateUserAction = ReturnType<typeof Users.actions.updateUser>
type DeleteUserAction = ReturnType<typeof Users.actions.deleteUser>

const redirectChannel = channel()

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

// sagas -----------

const fetchList = function* () {
  try {
    const data: UserData[] = yield apiClient.getUsers()
    const users = User.loadAll(data)
    yield put(Users.actions.succeededToFetchList(users))
  } catch (err) {
    console.error(err)
    yield put(Users.actions.failedToFetchList('取得に失敗しました'))
  }
}

const importNewUserData = function* (action: ImportNewUserDataAction) {
  const { users, errors }: NewUserOutput = yield CSVReader.readAsNewUser(action.payload)
  const { items: userGroups }: UserGroupsState = yield selectState((s) => s.userGroups)

  const result: Users.ImportNewUserDataResultPayload = {
    users: [],
    errors,
  }

  users.forEach((user) => {
    try {
      const userGroup = userGroups.find((group) => group.code === user.userGroupCode)
      if (!userGroup) throw new Error('企業が存在しません')

      validator.notEmpty(user.name, user.code)
      validator.userCode(user.code)
      validator.password(user.password)

      result.users.push({
        ...user,
        userGroupId: userGroup.id,
      })
    } catch (err) {
      const { message } = err as Error
      result.errors.push({
        row: user.row,
        message,
      })
    }
  })

  yield put(Users.actions.completedToImportNewUserData(result))
}

const addUsers = function* (action: AddUsersAction) {
  try {
    const data: UserData[] = yield apiClient.addUsers(action.payload)
    const users = User.loadAll(data)
    yield put(Users.actions.succeededToAddUsers(users))
  } catch (err) {
    console.error(err)
    yield put(Users.actions.failedToAddUsers('作成できませんでした'))
  }
}

const updateUser = function* (action: UpdateUserAction) {
  try {
    const { user, ...payload } = action.payload
    const body = {
      id: user.id,
      ...payload,
    }

    const data: UserData = yield apiClient.updateUser(body)
    const updatedUser = User.load(data)
    yield put(Users.actions.succeededToUpdateUser(updatedUser))
  } catch (err) {
    console.error(err)
    yield put(Users.actions.failedToUpdateUser('更新できませんでした'))
  }
}

const deleteUser = function* (action: DeleteUserAction) {
  try {
    const user = action.payload
    yield apiClient.deleteUser(user.id)
    yield put(Users.actions.succeededToDeleteUser(user))
  } catch (err) {
    console.error(err)
    yield put(Users.actions.failedToDeleteUser('削除できませんでした'))
  }
}

export default function* dataSaga() {
  yield takeEvery(Users.FETCH_LIST, fetchList)
  yield takeEvery(Users.IMPORT_NEW_USER_DATA, importNewUserData)
  yield takeEvery(Users.ADD_USERS, addUsers)
  yield takeEvery(Users.UPDATE_USER, updateUser)
  yield takeEvery(Users.DELETE_USER, deleteUser)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
