export const minutesToUnitTimes = (minutes: number) => {
  const days = Math.floor(minutes / 60 / 24)
  const hours = Math.floor((minutes / 60) % 24)
  const minutesInHour = Math.floor(minutes % 60)

  return {
    days,
    hours,
    minutes: minutesInHour,
  }
}
