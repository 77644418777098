import { LocationData } from 'utils/apiClient'

export default class Location {
  id: number
  latitude: number
  longitude: number
  altitude?: number
  horizontalAccuracy?: number
  verticalAccuracy?: number

  constructor(
    id: number,
    latitude: number,
    longitude: number,
    altitude?: number,
    horizontalAccuracy?: number,
    verticalAccuracy?: number,
  ) {
    this.id = id
    this.latitude = latitude
    this.longitude = longitude
    this.altitude = altitude
    this.horizontalAccuracy = horizontalAccuracy
    this.verticalAccuracy = verticalAccuracy
  }

  static load(data: LocationData): Location {
    return new Location(
      data.id,
      data.latitude,
      data.longitude,
      data.altitude,
      data.horizontalAccuracy,
      data.verticalAccuracy,
    )
  }
}
