import moment from 'moment'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User from 'redux/models/user'
import HeartRateTrend from 'redux/models/heartRateTrend'
import HeatRiskTrend from 'redux/models/heatRiskTrend'
import StressTrend from 'redux/models/stressTrend'
import { TrendType } from 'utils/trendType'

// Actions
export const FETCH_GRAPH_DATA = 'hamon/graphs/FETCH_GRAPH_DATA'
export const FETCH_GRAPH_DATA_COMPETION = 'hamon/graphs/FETCH_GRAPH_DATA_COMPETION'
export const CHANGE_TREND_TYPE = 'hamon/graphs/CHANGE_TREND_TYPE'
export const SWITCH_NEXT_TREND_RANGE = 'hamon/graphs/SWITCH_NEXT_TREND_RANGE'
export const SWITCH_PREVIOUS_TREND_RANGE = 'hamon/graphs/SWITCH_PREVIOUS_TREND_RANGE'
export const UPDATE_TREND_RANGE = 'hamon/graphs/UPDATE_TREND_RANGE'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface GraphData {
  user: User
  heartRates: HeartRateTrend[]
  heatRisks: HeatRiskTrend[]
  stresses: StressTrend[]
}

export interface UpdateRangePayload {
  type: TrendType
  from: moment.Moment
  to: moment.Moment
}

export const actions = {
  fetchGraphData: actionCreator<number>(FETCH_GRAPH_DATA),
  completedToFetchGraphData: actionCreator<GraphData | null>(FETCH_GRAPH_DATA_COMPETION),
  changeTrendType: actionCreator<TrendType>(CHANGE_TREND_TYPE),
  switchToNextTrendRange: actionCreator(SWITCH_NEXT_TREND_RANGE),
  switchToPreviousTrendRange: actionCreator(SWITCH_PREVIOUS_TREND_RANGE),
  updateTrendRange: actionCreator<UpdateRangePayload>(UPDATE_TREND_RANGE),
}

// Reducer
export interface GraphsState {
  fetching: boolean
  data: GraphData | null
  trend: {
    type: TrendType
    from: moment.Moment
    to: moment.Moment
  }
}

const initialState: () => GraphsState = () => ({
  fetching: false,
  data: null,
  trend: {
    type: TrendType.Day,
    from: moment().startOf('day'),
    to: moment().endOf('day'),
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchGraphData, (state) => ({
    ...state,
    fetching: true,
    data: null,
  }))
  .case(actions.completedToFetchGraphData, (state, data) => {
    return {
      ...state,
      fetching: false,
      data,
    }
  })
  .case(actions.updateTrendRange, (state, trend) => ({
    ...state,
    trend,
  }))
