import { channel } from 'redux-saga'
import { put, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'

import apiClient, { NotificationsResponseData } from 'utils/apiClient'
import * as Notifications from 'redux/modules/notifications'
import Notification from 'redux/models/notification'
import { AppState } from 'redux/store'
import { NormalizeResult } from '@geolonia/normalize-japanese-addresses'
import geocoder from 'utils/geocoder'

type FetchAction = ReturnType<typeof Notifications.actions.fetchNotifications>
type FetchForMapAction = ReturnType<typeof Notifications.actions.fetchNotificationsForMap>
type FetchForMapLocationAction = ReturnType<typeof Notifications.actions.fetchNotificationsForMapLocation>

const redirectChannel = channel()

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

const fetchNotifications = function* (action: FetchAction) {
  try {
    const page = action.payload
    const notifications: Notifications.NotificationsState = yield selectState(
      (s) => s.notifications,
    )

    const data: NotificationsResponseData = yield apiClient.getNotifications(
      page,
      notifications.list.limit,
    )
    const items = Notification.loadAll(data.notifications)

    yield put(
      Notifications.actions.completedToFetchNotifications({
        items,
        count: data.totalCount,
      }),
    )
  } catch (err) {
    console.error(err)
    yield put(Notifications.actions.completedToFetchNotifications(null))
  }
}

const fetchNotificationsForMap = function* (action: FetchForMapAction) {
  try {
    const { fromDate, toDate, userIds, types, limit } = action.payload
    const data: NotificationsResponseData = yield apiClient.getNotifications(
      1,
      limit,
      fromDate,
      toDate,
      userIds,
      types,
      true,
    )
    const items = Notification.loadAll(data.notifications)

    yield put(
      Notifications.actions.completedToFetchNotificationsForMap({
        items,
        count: data.totalCount,
      }),
    )
  } catch (err) {
    console.error(err)
    yield put(Notifications.actions.completedToFetchNotificationsForMap(null))
  }
}

const fetchNotificationsForMapLocation = function* (action: FetchForMapLocationAction) {
  try {
    const { locationName } = action.payload

    const addressData: NormalizeResult = yield geocoder.sreachAddress(
      locationName
    )

    yield put(
      Notifications.actions.completedToFetchNotificationsForMapLocation({
        addressData: addressData
      }),
    )
  } catch (err) {
    console.error(err)
    yield put(Notifications.actions.completedToFetchNotificationsForMapLocation(null))
  }
}

export default function* dataSaga() {
  yield takeEvery(Notifications.FETCH_NOTIFICATIONS, fetchNotifications)
  yield takeEvery(Notifications.FETCH_MAP_NOTIFICATIONS, fetchNotificationsForMap)
  yield takeEvery(Notifications.FETCH_MAP_LOCATION_NOTIFICATIONS, fetchNotificationsForMapLocation)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
