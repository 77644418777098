export const deepCopy = <T>(obj: T): T => {
  // Handle the 3 simple types, and null or undefined
  if (!obj || 'object' !== typeof obj) return obj

  // Handle Date
  if (obj instanceof Date) {
    const copy = new Date(obj.getTime())
    return (copy as unknown) as T
  }

  // Handle Array
  if (obj instanceof Array) {
    const copy = []
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i])
    }
    return (copy as unknown) as T
  }

  // Handle Object
  if (obj instanceof Object) {
    const copy: { [key: string]: unknown } = {}
    for (const attr in obj) {
      copy[attr] = deepCopy(((obj as unknown) as { [key: string]: unknown })[attr])
    }
    return (copy as unknown) as T
  }

  throw new Error("Unable to copy obj! Its type isn't supported.")
}
