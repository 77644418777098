import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import AlertAction, { AlertActionType } from 'redux/models/alertAction'

// Actions
export const FETCH_ALERT_ACITONS = 'hamon/alerts/FETCH_ALERT_ACITONS'
export const FETCH_ALERT_ACITONS_COMPLETION = 'hamon/alerts/FETCH_ALERT_ACITONS_COMPLETION'
export const SEND_ALERT_ACTION = 'hamon/alerts/SEND_ALERT_ACTION'
export const SEND_ALERT_ACTION_COMPETION = 'hamon/alerts/SEND_ALERT_ACTION_COMPETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface SendPayload {
  userId: number
  type: AlertActionType
  message: string
}

export const actions = {
  fetchAlertActions: actionCreator<number>(FETCH_ALERT_ACITONS),
  completedToFetchAlertActions: actionCreator<AlertAction[]>(FETCH_ALERT_ACITONS_COMPLETION),
  sendAlertAction: actionCreator<SendPayload>(SEND_ALERT_ACTION),
  completedToSendAlertAction: actionCreator<string | null>(SEND_ALERT_ACTION_COMPETION),
}

// Reducer
export interface AlertsState {
  fetching: boolean
  items: AlertAction[]
  sendingData: SendPayload | null
  sendError: string | null
}

const initialState: () => AlertsState = () => ({
  fetching: false,
  items: [],
  sendingData: null,
  sendError: null,
})

export default reducerWithInitialState(initialState())
  .case(actions.fetchAlertActions, (state) => ({
    ...state,
    fetching: true,
    items: [],
  }))
  .case(actions.completedToFetchAlertActions, (state, items) => ({
    ...state,
    fetching: false,
    items,
  }))
  .case(actions.sendAlertAction, (state, sendingData) => ({
    ...state,
    sendingData,
    error: null,
  }))
  .case(actions.completedToSendAlertAction, (state, error) => ({
    ...state,
    sendingData: null,
    error,
  }))
