import React, { PropsWithChildren } from 'react'
import { ThemeProvider, Button, PropTypes } from '@material-ui/core'
import { whiteTheme, blueTheme, greenTheme } from 'utils/theme'

interface ButtonProps {
  title: string
  color?: PropTypes.Color
  className?: string
  variant?: 'text' | 'outlined' | 'contained'
  size?: 'small' | 'medium' | 'large'
  bold?: boolean
  component?: React.ElementType
  startIcon?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

export const WhiteButton = (props: ButtonProps) => (
  <ThemeProvider theme={whiteTheme}>
    <Button
      className={props.className}
      style={{ fontWeight: props.bold ? 'bold' : 'normal', fontSize: 13 }}
      color="primary"
      variant={props.variant}
      size={props.size}
      startIcon={props.startIcon}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.title}
    </Button>
  </ThemeProvider>
)

export const BlueButton = (props: PropsWithChildren<ButtonProps>) => (
  <ThemeProvider theme={blueTheme}>
    <Button
      className={props.className}
      style={{ fontWeight: props.bold ? 'bold' : 'normal', fontSize: 13 }}
      component={props.component || 'button'}
      color="primary"
      variant={props.variant}
      size={props.size}
      startIcon={props.startIcon}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.title}
      {props.children}
    </Button>
  </ThemeProvider>
)

export const GreenButton = (props: PropsWithChildren<ButtonProps>) => (
  <ThemeProvider theme={greenTheme}>
    <Button
      className={props.className}
      style={{ fontWeight: props.bold ? 'bold' : 'normal', fontSize: 13 }}
      color={props.color || 'primary'}
      variant={props.variant}
      size={props.size}
      startIcon={props.startIcon}
      disabled={props.disabled}
      onClick={props.onClick}>
      {props.title}
      {props.children}
    </Button>
  </ThemeProvider>
)
