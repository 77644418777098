import React from 'react'
import clsx from 'clsx'

import User from 'redux/models/user'
import {
  Grid,
  createStyles,
  withStyles,
  WithStyles,
  Theme,
  Typography,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core'
import { Person } from '@material-ui/icons'
import Breadcrumbs from './Breadcrumbs'

interface OwnProps {
  className: string
  user: User
  onLogOut: () => void
  headerItem?: React.ReactElement
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.common.white,
    },
  })

type HeaderProps = WithStyles<typeof styles> & OwnProps

const Header = (props: HeaderProps) => {
  const { className, classes, user, headerItem, onLogOut } = props

  const checkLogout = () => {
    if (window.confirm('ログアウトしてよろしいですか？')) onLogOut()
  }

  return (
    <AppBar className={classes.container} position="sticky" elevation={1}>
      <Toolbar className={clsx(classes.container, className)}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Breadcrumbs />
          </Grid>
          <Grid item>{headerItem}</Grid>
          <Grid container item xs justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Person color="primary" />
            </Grid>
            <Grid item>
              <Typography>{user.name}</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => checkLogout()}>
                ログアウト
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(Header)
