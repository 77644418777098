import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Grid, Typography } from '@material-ui/core'
import menus from 'utils/navigationItems'

type OwnProps = RouteComponentProps

class Breadcrumbs extends React.Component<OwnProps> {
  render() {
    const { location } = this.props
    const { pathname } = location

    const menu = menus.flatMap((menu) => {
      const item = menu.items.find((item) => !!pathname.match(item.pattern))
      return item ? [item] : []
    })[0]
    const childItem = menu.children.find((child) => !!pathname.match(child.pattern))

    return (
      <Grid container spacing={2}>
        <Grid item>
          <Typography>{menu.title}</Typography>
        </Grid>
        {childItem && (
          <Grid item>
            <Typography>{childItem.title}</Typography>
          </Grid>
        )}
      </Grid>
    )
  }
}

export default withRouter(Breadcrumbs)
