import { createStyles, Theme } from '@material-ui/core'
import colors from 'utils/Color'

export default (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 800,
    },
  })

export const yearStyles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5),
    },
    slideButton: {
      fontSize: 15,
    },
  })

export const monthStyles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(0.5),
    },
    slideButton: {
      fontSize: 15,
    },
  })

export const weekStyles = (theme: Theme) =>
  createStyles({
    slideButton: {
      minWidth: 20,
      fontSize: 15,
    },
    weekdayContainer: {
      width: 250,
      margin: theme.spacing(0.5),
    },
    weekdayLabel: {
      fontSize: 13,
      color: colors.LightGray,
    },
    button: {
      width: 250,
      height: 35,
      margin: theme.spacing(0.5),
      padding: '5px 0',
    },
    labelContainer: {
      position: 'relative',
    },
    monthLabel: {
      position: 'absolute',
      top: -4,
      left: 0,
      fontSize: 8,
    },
    dayLabel: {
      fontSize: 13,
      fontWeight: 'bold',
    },
  })

export const dayStyles = (theme: Theme) =>
  createStyles({
    slideButton: {
      minWidth: 20,
      fontSize: 15,
    },
    weekdayContainer: {
      margin: theme.spacing(0.5),
    },
    weekdayLabel: {
      fontSize: 13,
      color: colors.LightGray,
    },
    weekContainer: {
      margin: theme.spacing(0.5),
    },
    button: {
      minWidth: 35,
      height: 35,
      margin: theme.spacing(0.5),
      padding: '5px 0',
    },
    labelContainer: {
      position: 'relative',
    },
    monthLabel: {
      position: 'absolute',
      top: -4,
      left: 0,
      fontSize: 8,
    },
    dayLabel: {
      fontSize: 13,
      fontWeight: 'bold',
    },
  })
