import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import { AWS_USER_POOL_ID, AWS_USER_POOL_APP_CLIENT_ID } from 'utils/Constants'

const poolData = {
  UserPoolId: AWS_USER_POOL_ID,
  ClientId: AWS_USER_POOL_APP_CLIENT_ID,
}
const userPool = new CognitoUserPool(poolData)
const flowType = 'USER_PASSWORD_AUTH'

// Functions ------------

const getAccessToken = async (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()

    if (!cognitoUser) {
      reject(new Error('No user'))
      return
    }

    cognitoUser.getSession((err: Error, session?: CognitoUserSession) => {
      if (!session) {
        reject(err)
      } else {
        resolve(session.getAccessToken().getJwtToken())
      }
    })
  })
}

const logIn = (userName: string, password: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const details = new AuthenticationDetails({
      Username: userName,
      Password: password,
    })
    const cognitoUser = new CognitoUser({
      Username: userName,
      Pool: userPool,
    })
    cognitoUser.setAuthenticationFlowType(flowType)
    cognitoUser.authenticateUser(details, {
      onSuccess: (result) => resolve(result.getAccessToken().getJwtToken()),
      onFailure: (err) => reject(err),
    })
  })
}

const logOut = () => {
  userPool.getCurrentUser()?.signOut()
}

const changePassword = (currentPassword: string, newPassword: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()

    if (!cognitoUser) {
      reject(new Error('No user'))
      return
    }

    cognitoUser.getSession((err: Error, session?: CognitoUserSession) => {
      if (!session) {
        reject(err)
        return
      }

      cognitoUser.changePassword(currentPassword, newPassword, (error, result) => {
        if (error || !result) {
          reject(error || new Error('Failed'))
          return
        }

        resolve()
      })
    })
  })
}

export default {
  getAccessToken,
  logIn,
  logOut,
  changePassword,
}
