import { channel } from 'redux-saga'
import { put, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'
import moment from 'moment'

import apiClient from 'utils/apiClient'
import { AppState } from 'redux/store'
import * as Graphs from 'redux/modules/graphs'
import User from 'redux/models/user'
import HeartRateTrend from 'redux/models/heartRateTrend'
import HeatRiskTrend from 'redux/models/heatRiskTrend'
import StressTrend from 'redux/models/stressTrend'
// import HeartRate from 'redux/models/heartRate'
import Stress from 'redux/models/stress'
import { Condition } from 'redux/models/userStatus'
import { TrendType } from 'utils/trendType'

type FetchAction = ReturnType<typeof Graphs.actions.fetchGraphData>
type ChangeTrendTypeAction = ReturnType<typeof Graphs.actions.changeTrendType>

const redirectChannel = channel()

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

// privates ----------------

const getUser = async (userId: number) => {
  const data = await apiClient.getUser(userId)
  return User.load(data)
}

const getHeartRateTrends = async (
  user: User,
  type: TrendType,
  from: moment.Moment,
  to: moment.Moment,
) => {
  const data = await apiClient.getHeartRateTrends(user.id, from.unix(), to.unix())
  const items = HeartRateTrend.loadAll(data)

  const keys: string[] = []
  const dict = items.reduce((dict, item) => {
    const keyDate = item.date.startOf(TrendType.graphUnit(type))
    const key = keyDate.format('YYYYMMDDHHmm')

    if (key in dict) {
      dict[key].push(item)
    } else {
      keys.push(key)
      dict[key] = [item]
    }
    return dict
  }, {} as { [key: string]: HeartRateTrend[] })

  return keys
    .map((key, index) => {
      const trends = dict[key]
      const baseTrend = trends[0]
      let min = 1000
      let max = 0
      let sum = 0
      let count = 0

      trends.forEach((trend) => {
        if (min > trend.minValue) min = trend.minValue
        if (max < trend.maxValue) max = trend.maxValue
        sum += trend.averageValue * trend.count
        count += trend.count
      })

      return new HeartRateTrend(
        index,
        baseTrend.date,
        min,
        max,
        sum / count,
        count,
        baseTrend.user,
        baseTrend.createdAt,
      )
    })
    .sort((a, b) => a.date.unix() - b.date.unix())
}

const getHeatRiskTrends = async (
  user: User,
  type: TrendType,
  from: moment.Moment,
  to: moment.Moment,
) => {
  const data = await apiClient.getHeatRiskTrends(user.id, from.unix(), to.unix())
  const items = HeatRiskTrend.loadAll(data)

  const keys: string[] = []
  const dict = items.reduce((dict, item) => {
    const keyDate = item.date.startOf(TrendType.graphUnit(type))
    const key = keyDate.format('YYYYMMDDHH')

    if (key in dict) {
      dict[key].push(item)
    } else {
      keys.push(key)
      dict[key] = [item]
    }
    return dict
  }, {} as { [key: string]: HeatRiskTrend[] })

  return keys
    .map((key, index) => {
      const trends = dict[key]
      const baseTrend = trends[0]
      let min = 1000
      let max = 0
      let sum = 0
      let count = 0

      trends.forEach((trend) => {
        if (min > trend.minValue) min = trend.minValue
        if (max < trend.maxValue) max = trend.maxValue
        sum += trend.averageValue
        count += trend.count
      })

      return new HeatRiskTrend(
        index,
        baseTrend.date,
        Math.round(min),
        Math.round(max),
        sum / trends.length,
        count,
        baseTrend.user,
        baseTrend.createdAt,
      )
    })
    .sort((a, b) => a.date.unix() - b.date.unix())
}

const getStressTrends = async (
  user: User,
  type: TrendType,
  from: moment.Moment,
  to: moment.Moment,
) => {
  const isDayTrend = to.diff(from, 'day') < 1

  let items: StressTrend[] = []

  if (isDayTrend) {
    // day トレンドは生データから
    const stresss = await apiClient.getStresss(user.id, from.unix(), to.unix())
    const hbStresss = await apiClient.getHBStresss(user.id, from.unix(), to.unix())
    const data = [...stresss, ...hbStresss]
    items = Stress.loadAll(data).map(
      (stress) =>
        new StressTrend(
          stress.id,
          stress.date,
          stress.value,
          stress.value,
          stress.value,
          stress.condition === Condition.Normal ? 1 : 0,
          stress.condition === Condition.Warning ? 1 : 0,
          stress.condition === Condition.Danger ? 1 : 0,
          stress.user,
          stress.createdAt,
        ),
    )
  } else {
    const data = await apiClient.getStressTrends(user.id, from.unix(), to.unix())
    items = StressTrend.loadAll(data)
  }

  const timestamps: number[] = []
  const keys: string[] = []
  const dict = items.reduce((dict, item) => {
    if (timestamps.includes(item.date.unix())) return dict
    timestamps.push(item.date.unix())

    const minutes = item.date.minute()
    const keyDate =
      type === TrendType.Day
        ? item.date.minute(Math.floor(minutes / 10) * 10).startOf('minute')
        : item.date.startOf(TrendType.graphUnit(type))
    const key = keyDate.format('YYYYMMDDHHmm')

    if (key in dict) {
      dict[key].push(item)
    } else {
      keys.push(key)
      dict[key] = [item]
    }
    return dict
  }, {} as { [key: string]: StressTrend[] })

  return keys
    .map((key, index) => {
      const trends = dict[key]
      const baseTrend = trends[0]
      let min = 1000
      let max = 0
      let sum = 0
      let normalCount = 0
      let warningCount = 0
      let dangerCount = 0

      trends.forEach((trend) => {
        const value = trend.averageValue
        if (min > value) min = value
        if (max < value) max = value
        sum += value
        normalCount += trend.normalCount
        warningCount += trend.warningCount
        dangerCount += trend.dangerCount
      })

      return new StressTrend(
        index,
        baseTrend.date,
        min,
        max,
        sum / trends.length,
        normalCount,
        warningCount,
        dangerCount,
        baseTrend.user,
        baseTrend.createdAt,
      )
    })
    .sort((a, b) => a.date.unix() - b.date.unix())
}

// sagas ----------------

const fetchGraphData = function* (action: FetchAction) {
  try {
    const graphs: Graphs.GraphsState = yield selectState((s) => s.graphs)
    const { type, from, to } = graphs.trend
    const userId = action.payload
    const user: User = yield getUser(userId)
    const [heartRates, heatRisks, stresses]: [
      HeartRateTrend[],
      HeatRiskTrend[],
      StressTrend[],
    ] = yield Promise.all([
      getHeartRateTrends(user, type, from, to),
      getHeatRiskTrends(user, type, from, to),
      getStressTrends(user, type, from, to),
    ])
    yield put(Graphs.actions.completedToFetchGraphData({ user, heartRates, heatRisks, stresses }))
  } catch (err) {
    console.error(err)
    yield put(Graphs.actions.completedToFetchGraphData(null))
  }
}

const changeTrendType = function* (action: ChangeTrendTypeAction) {
  const type = action.payload
  /*
  // 基準: from
  const graphs: Graphs.GraphsState = yield selectState((s) => s.graphs)
  const { from: currentFrom } = graphs.trend
  const from = moment(currentFrom).startOf(type)
  const to = moment(from).endOf(type)
  /*/
  // 基準: today
  const from = moment().startOf(type)
  const to = moment().endOf(type)
  //*/

  yield put(Graphs.actions.updateTrendRange({ type, from, to }))
}

const switchToNextTrendRange = function* () {
  const graphs: Graphs.GraphsState = yield selectState((s) => s.graphs)
  const { type, from: currentFrom } = graphs.trend
  const base = moment(currentFrom).add(1, TrendType.momentUnit(type))
  const from = moment(base).startOf(type)
  const to = moment(from).endOf(type)
  yield put(Graphs.actions.updateTrendRange({ type, from, to }))
}

const switchToPreviousTrendRange = function* () {
  const graphs: Graphs.GraphsState = yield selectState((s) => s.graphs)
  const { type, from: currentFrom } = graphs.trend
  const base = moment(currentFrom).subtract(1, TrendType.momentUnit(type))
  const from = moment(base).startOf(type)
  const to = moment(from).endOf(type)
  yield put(Graphs.actions.updateTrendRange({ type, from, to }))
}

export default function* dataSaga() {
  yield takeEvery(Graphs.FETCH_GRAPH_DATA, fetchGraphData)
  yield takeEvery(Graphs.CHANGE_TREND_TYPE, changeTrendType)
  yield takeEvery(Graphs.SWITCH_NEXT_TREND_RANGE, switchToNextTrendRange)
  yield takeEvery(Graphs.SWITCH_PREVIOUS_TREND_RANGE, switchToPreviousTrendRange)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
