import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as graphsActions, GraphsState, UpdateRangePayload } from 'redux/modules/graphs'
import Screen from './UsersGraphs'
import { TrendType } from 'utils/trendType'

export interface UsersGraphsActions {
  fetchGraphData: (userId: number) => Action<number>
  changeTrendType: (type: TrendType) => Action<TrendType>
  updateTrendRange: (payload: UpdateRangePayload) => Action<UpdateRangePayload>
  switchToNextTrendRange: () => Action<void>
  switchToPreviousTrendRange: () => Action<void>
}

export interface UsersGraphsStoreStates {
  graphs: GraphsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): UsersGraphsActions {
  return {
    fetchGraphData: (userId) => dispatch(graphsActions.fetchGraphData(userId)),
    changeTrendType: (type) => dispatch(graphsActions.changeTrendType(type)),
    updateTrendRange: (payload) => dispatch(graphsActions.updateTrendRange(payload)),
    switchToNextTrendRange: () => dispatch(graphsActions.switchToNextTrendRange()),
    switchToPreviousTrendRange: () => dispatch(graphsActions.switchToPreviousTrendRange()),
  }
}

function mapStateToProps(appState: AppState): UsersGraphsStoreStates {
  return { graphs: appState.graphs }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
