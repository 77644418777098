import React from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { NewUserStoreStates, NewUserActions } from '.'
import validator from 'utils/validator'

import { Grid, Typography, Paper, WithStyles, withStyles } from '@material-ui/core'
import {
  MainContainer,
  SectionHeader,
  FormContainer,
  Form,
  SelectForm,
  GreenButton,
} from 'components'
import { BlueButton } from 'components/Button'
import styles from './styles'

type NewUserProps = WithStyles<typeof styles> &
  RouteComponentProps &
  NewUserStoreStates &
  NewUserActions

interface UserInput {
  userGroupId: number
  name: string
  code: string
  password: string
}

interface NewUserStates {
  users: UserInput[]
  errors: string[]
}

class NewUser extends React.Component<NewUserProps, NewUserStates> {
  constructor(props: NewUserProps) {
    super(props)

    this.state = {
      users: [this.generateUserInput()],
      errors: [],
    }

    props.fetchUserGroupList()
  }

  componentDidUpdate(prevProps: NewUserProps) {
    const { importData: prevImportData, adding: prevAdding } = prevProps.users.add
    const { history, users } = this.props
    const { importData, adding, error } = users.add

    if (!prevImportData && importData) {
      // CSV読み込んだら更新
      this.mergeUserInputs()
    }

    if (prevAdding && !adding && !error) {
      // 追加が正常終了したら戻る
      history.goBack()
    }
  }

  generateUserInput = () => ({
    userGroupId: 0,
    name: '',
    code: '',
    password: '',
  })

  mergeUserInputs() {
    const { users: items } = this.state
    const { users } = this.props
    const { importData } = users.add
    if (!importData) return

    // 最初のデータが未入力ならドロップ
    const firstItem = items[0]
    if (
      firstItem.userGroupId === 0 &&
      firstItem.name + firstItem.code + firstItem.password === ''
    ) {
      items.shift()
    }

    this.setState({
      users: items.concat(importData.users),
      errors: importData.errors.map(({ row, message }) => `[${row}]: ${message}`),
    })
  }

  onValueChange(index: number, input: UserInput) {
    const { users } = this.state
    users[index] = input
    this.setState({ users })
  }

  onInput(file: File | null | undefined) {
    if (!file) return
    this.props.importNewUserData(file)
  }

  onSubmit() {
    const { users } = this.state
    const { userGroups } = this.props
    const userGroupIds = userGroups.items.map((i) => i.id)

    for (const user of users) {
      try {
        validator.selection(user.userGroupId, userGroupIds, '企業')
        validator.notEmpty(user.name, user.code)
        validator.userCode(user.code)
        validator.password(user.password)
      } catch (err) {
        alert(`${err}: ${JSON.stringify(user)}`)
        return
      }
    }

    try {
      const codes = users.map((u) => `${u.userGroupId}_${u.code}`)
      validator.duplication(codes, 'ユーザーコード')
    } catch (err) {
      alert(err)
      return
    }

    this.props.addUsers({ users })
  }

  render() {
    const { users: items, errors } = this.state
    const { classes, users, userGroups } = this.props
    const { adding, error } = users.add
    const isEmpty = items.reduce(
      (res, { userGroupId, name, code, password }) =>
        res || userGroupId === 0 || [name, code, password].includes(''),
      false,
    )

    const options = [{ label: '企業名', value: 0 }].concat(
      userGroups.items.map((item) => ({
        label: item.name,
        value: item.id,
      })),
    )

    return (
      <MainContainer>
        <SectionHeader>ユーザー登録</SectionHeader>

        {items.map((user, index) => (
          <FormContainer key={index}>
            <SelectForm
              value={`${user.userGroupId}`}
              label="所属企業"
              options={options}
              onChange={(value) =>
                this.onValueChange(index, { ...user, userGroupId: parseInt(value) })
              }
            />
            <Form
              value={user.code}
              label="ユーザーID"
              onChange={(code) => this.onValueChange(index, { ...user, code })}
            />
            <Form
              value={user.name}
              label="ユーザー名"
              onChange={(name) => this.onValueChange(index, { ...user, name })}
            />
            <Form
              value={user.password}
              label="初期パスワード"
              type="password"
              onChange={(password) => this.onValueChange(index, { ...user, password })}
            />
          </FormContainer>
        ))}

        {errors.map((error) => (
          <Typography color="secondary" key={error}>
            {error}
          </Typography>
        ))}

        <Paper square elevation={0} className={classes.actionContainer}>
          <Grid container direction="row">
            <Grid item xs>
              <BlueButton component="label" variant="contained" title="CSV からインポート">
                <input
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={(e) => this.onInput(e.target.files?.item(0))}
                />
              </BlueButton>
            </Grid>
            <Grid container item xs direction="column" alignItems="flex-end" spacing={1}>
              {error && (
                <Grid>
                  <Typography color="secondary">{error}</Typography>
                </Grid>
              )}
              <Grid item>
                <GreenButton
                  disabled={isEmpty || adding}
                  variant="contained"
                  title={adding ? '登録中' : '登録する'}
                  onClick={() => this.onSubmit()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </MainContainer>
    )
  }
}

export default withRouter(withStyles(styles)(NewUser))
