import { channel } from 'redux-saga'
import { put, takeEvery, take } from 'redux-saga/effects'

import apiClient, { AlertActionData } from 'utils/apiClient'
import * as Alerts from 'redux/modules/alerts'
import AlertAction from 'redux/models/alertAction'

type FetchAction = ReturnType<typeof Alerts.actions.fetchAlertActions>
type SendAction = ReturnType<typeof Alerts.actions.sendAlertAction>

const redirectChannel = channel()

const fetchAlertActions = function* (action: FetchAction) {
  try {
    const data: AlertActionData[] = yield apiClient.getAlertActions(action.payload)
    const items = AlertAction.loadAll(data)
    yield put(Alerts.actions.completedToFetchAlertActions(items))
  } catch (err) {
    console.error(err)
    yield put(Alerts.actions.completedToFetchAlertActions([]))
  }
}

const sendAlertAction = function* (action: SendAction) {
  try {
    yield apiClient.sendAlertAction(action.payload)
    yield put(Alerts.actions.completedToSendAlertAction(null))
  } catch (err) {
    console.error(err)
    yield put(Alerts.actions.completedToSendAlertAction('アクションの登録に失敗しました。'))
  }
}

export default function* dataSaga() {
  yield takeEvery(Alerts.FETCH_ALERT_ACITONS, fetchAlertActions)
  yield takeEvery(Alerts.SEND_ALERT_ACTION, sendAlertAction)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
