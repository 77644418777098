import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as userGroupsActions, UserGroupsState } from 'redux/modules/userGroups'
import { actions as usersActions, UsersState, UpdateUserPayload } from 'redux/modules/users'
import Screen from './EditUser'

export interface EditUserActions {
  fetchUserGroupList: () => Action<void>
  fetchUserList: () => Action<void>
  updateUser: (payload: UpdateUserPayload) => Action<UpdateUserPayload>
}

export interface EditUserStoreStates {
  userGroups: UserGroupsState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): EditUserActions {
  return {
    fetchUserGroupList: () => dispatch(userGroupsActions.fetchList()),
    fetchUserList: () => dispatch(usersActions.fetchList()),
    updateUser: (payload) => dispatch(usersActions.updateUser(payload)),
  }
}

function mapStateToProps(appState: AppState): EditUserStoreStates {
  return { userGroups: appState.userGroups, users: appState.users }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
