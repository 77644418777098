import React from 'react'
import clsx from 'clsx'
import { Typography, withStyles, WithStyles, Theme, Grid } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'
import { minutesToUnitTimes } from 'utils/time'

const VIEW_SIZE = 100
const VIEW_SIZE_HALF = VIEW_SIZE / 2
const STROKE_WIDTH = 4
const PATH_RADIUS = VIEW_SIZE_HALF - STROKE_WIDTH
const PATH_MARGIN = 1
const DIAMETER = Math.PI * 2 * PATH_RADIUS - PATH_MARGIN * 3

const styles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
    },
    textContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    label: {
      fontSize: 13,
      fontWeight: 'bold',
    },
    numberLabel: {
      margin: theme.spacing(0.2),
      fontSize: 20,
      fontWeight: 'bold',
    },
  })

type Props = WithStyles<typeof styles> & {
  minutes: number
  normal: number
  warning: number
  danger: number
  className?: string
}

const Path = ({ color, offsets, ratio }: { color: string; offsets: number[]; ratio: number }) => {
  const offset =
    PATH_MARGIN / 2 + offsets.reduce((sum, offset) => sum + offset * DIAMETER + PATH_MARGIN, 0)
  const dashes = [0, offset, ratio * DIAMETER, DIAMETER]

  const pathStyle: React.CSSProperties = {
    stroke: color,
    strokeWidth: STROKE_WIDTH,
    strokeDasharray: dashes.map((dash) => `${dash}px`).join(' '),
  }

  return (
    <path
      style={pathStyle}
      d={`
          M ${VIEW_SIZE_HALF},${VIEW_SIZE_HALF}
          m 0,-${PATH_RADIUS}
          a ${PATH_RADIUS},${PATH_RADIUS} 0 1 1 0,${2 * PATH_RADIUS}
          a ${PATH_RADIUS},${PATH_RADIUS} 0 1 1 0,-${2 * PATH_RADIUS}
        `}
      fillOpacity={0}
    />
  )
}

const StressCircle = (props: Props) => {
  const { classes, className, minutes, normal, warning, danger } = props
  const labelClass = classes.label
  const numberLabelClass = classes.numberLabel
  const unitTimes = minutesToUnitTimes(minutes)

  const timeLabel = (value: number, label: string) => {
    return (
      value > 0 && (
        <>
          <Typography component="span" className={numberLabelClass}>
            {value}
          </Typography>
          <Typography component="span" className={labelClass}>
            {label}
          </Typography>
        </>
      )
    )
  }

  return (
    <Grid className={classes.container}>
      <svg className={className} viewBox="0 0 100 100">
        <Path color={colors.ConditionGreen} offsets={[]} ratio={normal} />
        <Path color={colors.ConditionYellow} offsets={[normal]} ratio={warning} />
        <Path color={colors.ConditionRed} offsets={[normal, warning]} ratio={danger} />
      </svg>
      {minutes > 0 && (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={clsx(className, classes.textContainer)}>
          <Typography className={classes.label}>総取得時間</Typography>
          <Typography className={classes.label}>
            {timeLabel(unitTimes.days, '日')}
            {timeLabel(unitTimes.hours, '時間')}
            {timeLabel(unitTimes.minutes, '分')}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(StressCircle)
