import React from 'react'
import moment from 'moment'
import { Button, withStyles, WithStyles, Grid } from '@material-ui/core'

import { SERVICE_LAUNCHED_AT } from 'utils/Constants'
import CalendarButton from './CalendarButton'
import { yearStyles } from './styles'

type YearsProps = WithStyles<typeof yearStyles> & {
  activeDate: moment.Moment
  onChange: (from: moment.Moment, to: moment.Moment) => void
}

interface YearsStates {
  offset: number
}

class Years extends React.Component<YearsProps, YearsStates> {
  constructor(props: YearsProps) {
    super(props)
    this.state = { offset: 0 }
  }

  render() {
    const { offset } = this.state
    const { classes, activeDate, onChange } = this.props

    const launchedDate = moment(SERVICE_LAUNCHED_AT).startOf('year')
    const activeYear = activeDate.year()
    const baseYear = activeYear + offset
    const years = [baseYear - 1, baseYear, baseYear + 1]

    return (
      <Grid item container direction="row">
        <Button
          className={classes.slideButton}
          disabled={moment([baseYear]).isBefore(launchedDate)}
          onClick={() => this.setState({ offset: offset - 1 })}>
          ＜
        </Button>
        {years.map((year) => {
          const date = moment([year])
          return (
            <CalendarButton
              className={classes.button}
              key={year}
              selected={year === activeYear}
              disabled={date.isBefore(launchedDate) || date.isAfter(moment())}
              title={`${year}`}
              onClick={() => onChange(date, moment(date).endOf('year'))}
            />
          )
        })}
        <Button
          className={classes.slideButton}
          disabled={moment([baseYear]).isAfter(moment())}
          onClick={() => this.setState({ offset: offset + 1 })}>
          ＞
        </Button>
      </Grid>
    )
  }
}

export default withStyles(yearStyles)(Years)
