import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as observationsActions,
  ObservationsState,
  AnswerPayload,
} from 'redux/modules/observations'
import Screen from './ObservationRequests'

export interface ObservationRequestsActions {
  fetchObservationRequests: () => Action<void>
  sendAnsewr: (payload: AnswerPayload) => Action<AnswerPayload>
}

export interface ObservationRequestsStoreStates {
  observations: ObservationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<unknown>>): ObservationRequestsActions {
  return {
    fetchObservationRequests: () => dispatch(observationsActions.fetchObservationRequests()),
    sendAnsewr: (payload) => dispatch(observationsActions.sendObservationRequestAnswer(payload)),
  }
}

function mapStateToProps(appState: AppState): ObservationRequestsStoreStates {
  return { observations: appState.observations }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
