import React, { PropsWithChildren } from 'react'
import { Typography, withStyles, WithStyles, Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/core'
import colors from 'utils/Color'

const styles = (theme: Theme) =>
  createStyles({
    sectionHeader: {
      color: colors.LightGray,
      fontWeight: 'bold',
      fontSize: 16,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })

type Props = PropsWithChildren<WithStyles<typeof styles>>

const SectionHeader = (props: Props) => (
  <Typography className={props.classes.sectionHeader}>{props.children}</Typography>
)

export default withStyles(styles)(SectionHeader)
