import styled from 'styled-components'

import Connection from 'images/connection.svg'
import Graph from 'images/graph.svg'
import Logo from 'images/logo.svg'

// condition
import Drowsiness from 'images/drowsiness.svg'
import HeatBad from 'images/heat_bad.svg'
import HeatGood from 'images/heat_good.svg'
import HeatWarning from 'images/heat_warning.svg'
import HyperthermiaBad from 'images/hyperthermia_bad.svg'
import HyperthermiaGood from 'images/hyperthermia_good.svg'
import HyperthermiaWarning from 'images/hyperthermia_warning.svg'

// pins
import ConditionPin from 'images/pins/condition.svg'
import FallPin from 'images/pins/fall.svg'
import HeatstrokePin from 'images/pins/heatstroke.svg'
import SleepinessPin from 'images/pins/sleepiness.svg'
import StressPin from 'images/pins/stress.svg'

interface IconProps {
  size?: number
}

export const Icons = {
  Connection,
  Graph,
  Logo,
}

export const PinsIcon = {
  ConditionPin,
  FallPin,
  HeatstrokePin,
  SleepinessPin,
  StressPin,
}

export const ConditionIcon = {
  Drowsiness,
  HeatBad,
  HeatGood,
  HeatWarning,
  HyperthermiaBad,
  HyperthermiaGood,
  HyperthermiaWarning,
}

export default styled.img`
  width: ${(props: IconProps) => (props.size ? props.size : 18)}px;
  height: ${(props: IconProps) => (props.size ? props.size : 18)}px;
  cursor: pointer;
`
