import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'

import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core'
import {
  MainContainer,
  SectionHeader,
  FormContainer,
  Form,
  PeriodForm,
  GreenButton,
} from 'components'
import { NewCompanyStoreStates, NewCompanyActions } from '.'
import validator from 'utils/validator'
import styles from './styles'

type NewCompanyProps = RouteComponentProps &
  WithStyles<typeof styles> &
  NewCompanyStoreStates &
  NewCompanyActions

interface NewCompanyStates {
  name: string
  code: string
  availableFrom: moment.Moment | null
  availableTo: moment.Moment | null
}

class NewCompany extends React.Component<NewCompanyProps, NewCompanyStates> {
  constructor(props: NewCompanyProps) {
    super(props)

    this.state = {
      name: '',
      code: '',
      availableFrom: null,
      availableTo: null,
    }
  }

  componentDidUpdate(prevProps: NewCompanyProps) {
    const prevValue = prevProps.userGroups.add.adding
    const { history, userGroups } = this.props
    const currentValue = userGroups.add.adding

    if (prevValue && !currentValue && !userGroups.add.error) {
      // 追加が正常終了したら戻る
      history.goBack()
    }
  }

  onSubmit() {
    const { name, code, availableFrom, availableTo } = this.state

    try {
      validator.notEmpty(name, code)
      validator.groupCode(code)
    } catch (err) {
      alert(err)
      return
    }

    this.props.addCompany({
      name,
      code,
      availableFrom: availableFrom?.startOf('day') || null,
      availableTo: availableTo?.startOf('day') || null,
    })
  }

  render() {
    const { name, code, availableFrom, availableTo } = this.state
    const { userGroups } = this.props
    const { adding, error } = userGroups.add
    const isEmpty = [name, code].includes('')

    return (
      <MainContainer>
        <SectionHeader>企業登録</SectionHeader>
        <FormContainer>
          <Form value={name} label="企業名" onChange={(name) => this.setState({ name })} />
          <Form value={code} label="企業コード" onChange={(code) => this.setState({ code })} />
          <PeriodForm
            from={availableFrom}
            to={availableTo}
            label="利用可能期間"
            minDate={moment().startOf('day')}
            onChange={(from, to) =>
              this.setState({
                availableFrom: from,
                availableTo: to,
              })
            }
          />
          <Grid container direction="column" alignItems="flex-end" spacing={1}>
            {error && (
              <Grid>
                <Typography color="secondary">{error}</Typography>
              </Grid>
            )}
            <Grid item>
              <GreenButton
                disabled={isEmpty || adding}
                variant="contained"
                title={adding ? '登録中' : '登録する'}
                onClick={() => this.onSubmit()}
              />
            </Grid>
          </Grid>
        </FormContainer>
      </MainContainer>
    )
  }
}

export default withStyles(styles)(NewCompany)
