export interface NewUserOutput {
  users: {
    row: string
    userGroupCode: string
    code: string
    name: string
    password: string
  }[]
  errors: {
    row: string
    message: string
  }[]
}

export default class CSVReader {
  static readCSV(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        const content = fileReader.result

        if (typeof content !== 'string') return reject('File format is invalid')

        const lines = content.split('\r\n').filter((line) => line !== '')
        const rows = lines.map((line) => line.split(','))
        resolve(rows)
      }
      fileReader.onerror = () => {
        reject("File can't read")
      }
      fileReader.readAsText(file)
    })
  }

  static async readAsNewUser(file: File): Promise<NewUserOutput> {
    const rows = await this.readCSV(file)

    const output: NewUserOutput = {
      users: [],
      errors: [],
    }

    rows.forEach((row) => {
      if (row.length !== 4) {
        output.errors.push({
          row: row.join(','),
          message: 'フォーマットが違います',
        })
        return
      }

      output.users.push({
        row: row.join(','),
        userGroupCode: row[0],
        code: row[1],
        name: row[2],
        password: row[3],
      })
    })

    return output
  }
}
