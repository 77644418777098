import React from 'react'
import {
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
    },
    caption: {
      whiteSpace: 'pre-wrap',
    },
    error: {
      whiteSpace: 'pre-wrap',
      color: '#ff0000',
    },
  })

// NormalTypography --------------

type NormalTypographyProps = WithStyles<typeof styles> & {
  text: string
}

const _NormalTypography = (props: NormalTypographyProps) => {
  const { classes, text } = props

  return (
    <Grid item>
      <Typography className={classes.caption} variant="caption">{text}</Typography>
    </Grid>
  )
}

export const NormalTypography = withStyles(styles)(_NormalTypography)

// ErrorTypography --------------

type ErrorTypographyProps = WithStyles<typeof styles> & {
  text: string
}

const _ErrorTypography = (props: ErrorTypographyProps) => {
  const { classes, text } = props

  return (
    <Grid item>
      {text !== "" &&
        <Typography className={classes.error} variant="caption">{text}</Typography>
      }
    </Grid>
  )
}

export const ErrorTypography = withStyles(styles)(_ErrorTypography)